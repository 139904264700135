import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { SystemConst } from '@core/constant/api-main/system.const';
import { CreateSidebarMenuGroupModel, SidebarMenuGroupModel, UpdateSidebarMenuGroupModel } from '@shared/models/pages/system-management/menu-config.model';
import { ApiReturnModel } from '@shared/models/global.model';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class MenuConfigService {
  //auth
  systemApiUrl = environment.SYSTEM_API_URL;
  mainApiUrl = environment.MAIN_API_URL;
  sidebarMenu: SidebarMenuGroupModel[] = [];

  constructor(private http: HttpClient, private toast: ToastrService) { }

  //#region System / Sidebar - GET LIST SIDEBAR MENU FOR RENDER SIDEBAR
  getListSystemSidebarMenus(sort: string, sortBy: string, searchKey:string){
    const url = this.mainApiUrl.concat(SystemConst.SYSTEM_SIDEBAR_GET_LIST)
                              .replace("{sort}", sort)
                              .replace("{sortBy}", sortBy)
                              .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }

  getChildrenByCodeAndRouting(code: string, routing: string, callback) {
    if (this.sidebarMenu.length > 0) {
      let data = this.findSubmenu(code, routing);
      callback(data);
    } else {
      this.getListSystemSidebarMenus("asc", "index", "").subscribe(rs => { 
        let result = rs as ApiReturnModel;
        if (result.code == 0) {
          this.sidebarMenu = result.data;
          let data = this.findSubmenu(code, routing);
          callback(data);
        } else {
          this.sidebarMenu = [];
        }
      }, error => {
        this.toast.error(error.message);
      })
    };
  }

  findSubmenu(code: string, routing: string){
    let parent, child;
    for (let item of this.sidebarMenu) {
      if (item.code === code) {
        parent = item;
        let menu = item.childrend;
        for (let submenu of menu) {
          if (submenu.layerUrl) {
            if (submenu.layerUrl.includes("/log/system")) submenu.layerUrl = submenu.layerUrl.replace("/log/system", "/log"); //special for log which have 2 tabs system and database
            if (routing.includes(submenu.layerUrl)) {
              child = submenu;
              break;
            }
          }
        }
      }
    }
    return {
      menu: parent,
      submenu: child
    };
  }
  //#endregion

  //#region System / Config / Sidebar Menu (SidebarMenuGroup)
  getListSidebarMenuGroups(sort: string, sortBy: string, searchKey:string){
    const url = this.mainApiUrl.concat(SystemConst.SIDEBAR_GROUP_MENU_GET_LIST)
                              .replace("{sort}", sort)
                              .replace("{sortBy}", sortBy)
                              .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }

  getListPagingSidebarMenuGroups(page: number, pageSize: number, sort: string, sortBy: string, searchKey: string){
    const url = this.mainApiUrl.concat(SystemConst.SIDEBAR_GROUP_MENU_LIST_PAGING)
                                .replace("{page}", page + "")
                                .replace("{pageSize}", pageSize + "")
                                .replace("{searchKey}", searchKey)
                                .replace("{sort}", sort)
                                .replace("{sortBy}", sortBy);
    return this.http.get(url);
  }

  readSidebarMenuGroup(id: string) {
    const url = this.mainApiUrl.concat(SystemConst.SIDEBAR_GROUP_MENU_CRUD)
                                .replace("{id}", id);
    return this.http.get(url);
  }

  createSidebarMenuGroup(data: CreateSidebarMenuGroupModel){
    const url = this.mainApiUrl.concat(SystemConst.SIDEBAR_GROUP_MENU_CRUD)
                                .replace("/{id}", "");
    return this.http.post(url, data);
  }

  updateSidebarMenuGroup(id: string, data: UpdateSidebarMenuGroupModel){
    const url = this.mainApiUrl.concat(SystemConst.SIDEBAR_GROUP_MENU_CRUD).replace("{id}", id);
    return this.http.put(url, data);
  }

  deleteSidebarMenuGroup(id: string){
    const url = this.mainApiUrl.concat(SystemConst.SIDEBAR_GROUP_MENU_CRUD).replace("{id}", id);
    return this.http.delete(url);
  }
  //#endregion

  //#region System / MenuClass
  getListMenuClasses(sort: string, sortBy: string, searchKey:string){
    const url = this.mainApiUrl.concat(SystemConst.MENU_GET_LIST)
                              .replace("{sort}", sort)
                              .replace("{sortBy}", sortBy)
                              .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }

  getListPagingMenuClasses(page: number, pageSize: number, sort: string, sortBy: string, searchKey: string){
    const url = this.mainApiUrl.concat(SystemConst.MENU_GET_LIST_PAGING)
                                .replace("{page}", page + "")
                                .replace("{pageSize}", pageSize + "")
                                .replace("{searchKey}", searchKey)
                                .replace("{sort}", sort)
                                .replace("{sortBy}", sortBy);
    return this.http.get(url);
  }

  readMenuClass(id: string) {
    const url = this.mainApiUrl.concat(SystemConst.MENU_CRUD)
                                .replace("{id}", id);
    return this.http.get(url);
  }

  createMenuClass(data){
    const url = this.mainApiUrl.concat(SystemConst.MENU_CRUD)
                                .replace("/{id}", "");
    return this.http.post(url, data);
  }

  updateMenuClass(id: string, data){
    const url = this.mainApiUrl.concat(SystemConst.MENU_CRUD).replace("{id}", id);
    return this.http.put(url, data);
  }

  deleteMenuClass(id: string){
    const url = this.mainApiUrl.concat(SystemConst.MENU_CRUD).replace("{id}", id);
    return this.http.delete(url);
  }
  //#endregion
}
