import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'selectbox',
  templateUrl: './selectbox.component.html',
  styleUrls: ['./selectbox.component.scss']
})
export class SelectboxComponent implements OnInit {
  @Input() data: any;
  @Input() title: string;
  @Input() selectedId: string;
  @Output() onSelect = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onChange($event){
    let id = $event.target.value;
    let data;
    for (let item of this.data){
      if (item.id === id) data = item;
    }
    this.onSelect.emit({
      id: id,
      data: data
    })
  }

  refresh() {
    this.selectedId = '';
  }

}
