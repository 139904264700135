<ng-template #roleInforModal let-modal>
    <div class="modal-body text-center bg-white p-0">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title mb-5">{{'role-infor-modal_title' | translate}}</h4>
                <button class="btn-close" [tooltip]="'global_close' | translate" (click)="close()"><i class="mdi mdi-window-close mdi-18px"></i></button>
                <ul class="verti-timeline list-unstyled">
                    <li class="event-list">
                        <!-- <div class="event-timeline-dot">
                            <i class="mdi mdi-account-star-outline mdi-18px"></i>
                        </div> -->
                        <div class="row">
                            <div class="col-3">
                                <h5 class="font-size-14 text-warning">
                                    <label class="badge badge-pill badge-bg-warning">ADMIN</label>
                                </h5>
                            </div>
                            <div class="col-1">
                                <i class="mdi mdi-arrow-right font-size-16 text-primary align-middle ml-2"></i>
                            </div>
                            <div class="col-8 detail-role">
                                {{'role-infor-modal_admin_detail' | translate}}
                            </div>
                        </div>
                    </li>
                    <li class="event-list">
                        <div class="row">
                            <div class="col-3">
                                <h5 class="font-size-14 text-primary">
                                    <label class="badge badge-pill badge-bg-primary">OPERATOR</label> 
                                </h5>
                            </div>
                            <div class="col-1">
                                <i class="mdi mdi-arrow-right font-size-16 text-primary align-middle ml-2"></i>
                            </div>
                            <div class="col-8 detail-role">
                                {{'role-infor-modal_operator_detail' | translate}}
                            </div>
                        </div>
                    </li>
                    <li class="event-list active">
                        <div class="row">
                            <div class="col-3">
                                <h5 class="font-size-14 text-info">
                                    <label class="badge badge-pill badge-bg-info">LGSP</label> 
                                </h5>
                            </div>
                            <div class="col-1">
                                <i class="mdi mdi-arrow-right font-size-16 text-primary align-middle ml-2"></i>
                            </div>
                            <div class="col-8 detail-role">
                                {{'role-infor-modal_lgsp_detail' | translate}}
                            </div>
                        </div>
                    </li>
                    <li class="event-list">
                        <div class="row">
                            <div class="col-3">
                                <h5 class="font-size-14 text-success text-uppercase">
                                    <label class="badge badge-pill badge-bg-success">{{'global_more' | translate}}</label> 
                                </h5>
                            </div>
                            <div class="col-1">
                                <i class="mdi mdi-arrow-right font-size-16 text-primary align-middle ml-2"></i>
                            </div>
                            <div class="col-8 detail-role">
                                {{'role-infor-modal_other_detail' | translate}}
                            </div>
                        </div>
                    </li>
                </ul>
                <!-- <div class="text-center mt-4"><a href="javascript: void(0);" class="btn btn-primary waves-effect waves-light btn-sm">View More <i class="mdi mdi-arrow-right ms-1"></i></a></div> -->
            </div>
        </div>
    </div>
</ng-template>