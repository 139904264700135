import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constant } from '@core/constant/api-system/Constant';
import { LoginModel, LoginSuccessDataModel, ResetPassModel } from '@shared/models/auth.model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CacheAll } from '@core/lib/cache';
import { TTL, CacheConst } from '@core/constant/shared/system.const';
import { Base64 } from '@core/lib/encode';
import { ApiReturnModel } from '@shared/models/global.model';
import { sha256 } from 'js-sha256';
import { AES } from '@core/lib/crypto';
import { AppPageRoutingEnum } from '@shared/enums/routing.enum';
import { UserRolesEnum } from '@shared/enums/role.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  systemApiUrl = environment.SYSTEM_API_URL;
  loginData: LoginModel;
  userId = CacheAll.getCookie(CacheConst.USER_ID);
  deviceId = CacheAll.getCookie(CacheConst.DEVICE_ID);
  userDetail: any;
  token: string = CacheAll.getCookie(CacheConst.USER_TOKEN);
  anonymousToken: string = CacheAll.getCookie(CacheConst.ANONYMOUS_TOKEN);

  constructor( private http: HttpClient, public routerService: Router, public toast: ToastrService, public translate: TranslateService ) { 
    if (!this.anonymousToken) this.loginForAnonymous();
  }

  isLoginUser(): boolean{
    let token = CacheAll.getCookie(CacheConst.USER_TOKEN);
    if (token) return true;
    else return false;
  }

  checkValidUser(callback){
    let token = CacheAll.getCookie(CacheConst.USER_TOKEN);
    this.checkValidToken(token).subscribe((rs) => {
      let result = rs as ApiReturnModel;
      if (result.code == 0) {
        callback(true)
      } else {
        callback(false)
      }
    }, (error) => {
      callback(false);
    })
  }

  checkInvalidResponse(data: ApiReturnModel) :boolean{
    if (data.code == 1){
      CacheAll.deleteCookie(CacheConst.USER_TOKEN);
      CacheAll.deleteCookie(CacheConst.USER_ID);
      CacheAll.deleteCookie(CacheConst.USER_IMAGE);
      CacheAll.deleteCookie(CacheConst.USER_DETAIL);
      CacheAll.deleteCookie(CacheConst.USER_SESSION);
      CacheAll.deleteCookie(CacheConst.CUSTOMER_CODE);
      let currentUrl = window.location.pathname;
      this.routerService.navigate(['auth'], { queryParams: {redirectUrl: currentUrl} });
      if (this.translate) this.toast.error(this.translate.instant("auth_invalid-user"));
      else this.toast.error("Người dùng không hợp lệ, vui lòng đăng nhập lại!");
      return true;
    } else {
      return false;
    }
  }

  isAdmin(): boolean{
    if (this.userDetail == null) {
      this.userDetail = this.getCurrentUser();
    }
    if (this.userDetail) {
      if (this.userDetail.role) {
        if (this.userDetail.role.code === UserRolesEnum.ADMIN) return true
      }
    }

    return false;
  }

  getCurrentRole(): string{
    if (this.userDetail == null) {
      this.userDetail = this.getCurrentUser();
    }
    if (this.userDetail && this.userDetail.role) {
      return this.userDetail.role.code;
    } else {
      return null
    }
  }

  getCurrentUser(){
    if (CacheAll.getCookie(CacheConst.USER_DETAIL)) return JSON.parse(Base64.decode(CacheAll.getCookie(CacheConst.USER_DETAIL)));
    else return null;
  }

  checkLoginType(str: string){
    if (str.includes('@') && str.includes('.')){
      return 'mail';
    } else if (!isNaN(parseFloat(str)) && this.checkPhoneNumber(str)){
      return 'phone';
    } else {
      return 'username';
    }
  }

  checkPhoneNumber(value) :boolean{
    let pattern = new RegExp(/0+(2\d\d|3[2-9]|5[6|8|9]|9\d(?!5)|8[1-9]|7[0|6-9])+([0-9]{7})\b/);
    let valid =  pattern.test(value);
    if (!valid) {
      return  false
    }
    else return true;
  }

  login(data: LoginModel){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    
    const url = this.systemApiUrl.concat(Constant.AUTH_LOGIN);
    this.loginData = data;
    return this.http.post(url, data, {headers: headers});
  }

  logout(){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    
    const url = this.systemApiUrl.concat(Constant.AUTH_LOGOUT)
                                  .replace('{user_id}', this.userId)
                                  .replace('{device_id}', this.deviceId);
    return this.http.get(url, {headers: headers});
  }

  resetPassword(data: ResetPassModel){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = this.systemApiUrl.concat(Constant.AUTH_RESET_PASS);
    return this.http.post(url, data, {headers: headers});
  }

  checkValidToken(token: string){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });

    const url = this.systemApiUrl.concat(Constant.AUTH_TOKEN_VALIDATE);
    return this.http.get(url, {headers: headers});
  }

  checkToken(){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    });

    const url = this.systemApiUrl.concat(Constant.AUTH_TOKEN_VALIDATE);
    return this.http.get(url, {headers: headers});
  }

  redirectToLoginPage(){
    if (!(this.routerService.url == '/' || this.routerService.url == '/auth/login' || this.routerService.url == '/auth/forget-password')){
      this.toast.error(this.translate.instant('auth_out-of-session'));
      this.routerService.navigate(['auth/login'], {});
    }
  }

  encryptedPassword(password: string){
    return sha256.hex(password)
  }

  registerUser(data) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const url = this.systemApiUrl.concat(Constant.AUTH_REGISTER);
    return this.http.post(url, data, {headers: headers});
  }

  loginForAnonymous(){
    let uuid = AES.hash(new Date().getTime() + "");
    let deviceId = "WEB-".concat(uuid);
    let loginData = {
      "username": "Anonymous",
      "phone": null,
      "email": null,
      "password": this.encryptedPassword("password"),
      "deviceId": deviceId,
      "otpCode": null
    }
    this.login(loginData).subscribe((rs) => {
      let result = rs as ApiReturnModel;
      if (result.code === 0 && result.data) {
        let data = result.data as LoginSuccessDataModel;
        CacheAll.setCookie(CacheConst.ANONYMOUS_TOKEN, data.accessToken, TTL.TOKEN);
      } 
    }, (error) => {
      this.toast.error(error.message);
    })
  }

  //#region Document
  getDocumentUrlToView(docCode: string){
    const url = this.systemApiUrl.concat(Constant.GET_DOC_TO_VIEW)
                              .replace("{docCode}", docCode)
                              .replace("{token}", this.token);
    return url;
  }

  downloadDocumentUrlByDocCode(docCode: string){
    const url = this.systemApiUrl.concat(Constant.GET_DOC_TO_VIEW)
                              .replace("{docCode}", docCode)
                              .replace("{token}", this.token);
    return this.http.get(url);
  }
  //#endregion
}
