import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Constant as MainConst } from '@core/constant/api-main/Constant';
import { Constant as SystemConst } from '@core/constant/api-system/Constant';
import { Constant as TrackingConst} from '@core/constant/api-tracking/Constant';
import { SidebarMenuModel } from '@shared/models/sidebar.model';
import { ToastrService } from 'ngx-toastr';
import { ObjClassModel } from '@shared/models/main.model';
import { CommonCatsConst } from '@core/constant/api-main/common-cats.const';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
/**
  * // INTRO: This service is used to call frequently used functions in the project
  */
export class MainService {
  trackingApiUrl = environment.MAIN_API_URL;
  systemApiUrl = environment.SYSTEM_API_URL;
  mainApiUrl = environment.MAIN_API_URL;
  sidebarMenu: SidebarMenuModel[] = [];

  constructor(private http: HttpClient, public toast: ToastrService, private translate: TranslateService) { }
  getCustomerInfo(){
    const url = this.trackingApiUrl.concat(TrackingConst.CUSTOMER_INFO);
    return this.http.get(url);
  }
  //#region Object Class
  getListObjClassByFunc(searchKey: string, funcModuleCode: string, funcMenuCode: string){
    const url = this.systemApiUrl.concat(SystemConst.OBJECT_CLASS_GET_LIST)
                                        .replace("{searchKey}", searchKey)
                                        .replace("{funcModuleCode}", funcModuleCode)
                                        .replace("{funcMenuCode}", funcMenuCode);    
    return this.http.get(url);
  }

  getListObjClass(funcCode: string, sort: string, sortBy: string, searchKey:string, hasPerm?: number){    
    let url = this.systemApiUrl.concat(MainConst.LAYER_LIST_GET_LIST)
                                  .replace("{searchKey}", searchKey)
                                  .replace("{sort}", sort)
                                  .replace("{sortBy}", sortBy)
                                  .replace("{funcCode}", funcCode);
    if (typeof hasPerm == "number" && (hasPerm == 0 || hasPerm == 1)) url = url.concat("&hasPerms=" + hasPerm);

    return this.http.get(url);
  }

  getDetailObjClass(id: string){
    const url = this.systemApiUrl.concat(MainConst.LAYER_LIST_GET_DETAIL).replace("{layer_id}", id);
    return this.http.get(url);
  }

  getObjClassByCodeAndSubCode(code: string, subCode: string) {
    let url = this.systemApiUrl.concat(MainConst.LAYER_LIST_GET_LIST_BY_CODE)
                              .replace("{code}", code);

    if (subCode) url = url.replace("{subCode}", subCode)
    else url = url.replace("&subCode={subCode}", "")
    
    return this.http.get(url);
  }

  updateObjClass(id: string, data) {
    const url = this.systemApiUrl.concat(MainConst.LAYER_LIST_UPDATE).replace("{layer_id}", id);
    return this.http.put(url, data);
  }

  getObjClassFromListByCode(code: string, listObjClasses: ObjClassModel[]){
    let oc = new ObjClassModel; 
    for (let item of listObjClasses) {
      if (item.code === code) {
        oc = item;
        break;
      }
    }
    return oc;
  }
  //#endregion

  //#region Object Field
  getListObjFields(code: string, subCode: string, searchKey: string){
    if (!subCode) subCode = ""
    
    const url = this.systemApiUrl.concat(MainConst.LAYER_GET_FIELDS_WITH_SEARCH).replace("{layer_code}", code)
                                                                        .replace("{layer_subCode}", subCode)
                                                                        .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }

  getDetailObjField(id: string){
    const url = this.systemApiUrl.concat(MainConst.LAYER_GET_FIELDS_DETAILS).replace("{field_id}", id);
    return this.http.get(url);
  }

  updateSharingObjField(id: string, allowShare: boolean){
    let data = {
      allowShare: allowShare ? 1 : 0
    }
    
    const url = this.systemApiUrl.concat(MainConst.LAYER_UPDATE_FIELDS).replace("{field_id}", id);
    return this.http.put(url, data);
  }

  updateObjField(id: string, data){
    const url = this.systemApiUrl.concat(MainConst.LAYER_UPDATE_FIELDS).replace("{field_id}", id);
    return this.http.put(url, data);
  }

  getListCriteriaObjFields(code: string, subCode: string, searchKey: string) {
    if (!subCode) subCode = ""
    
    const url = this.systemApiUrl.concat(MainConst.LAYER_GET_CRITERIA_FIELDS).replace("{layer_code}", code)
                                                                        .replace("{layer_subCode}", subCode)
                                                                        .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }
  //#endregion

  //#region Object Type
  getListObjTypes(searchKey: string){
    const url = this.mainApiUrl.concat(CommonCatsConst.OBJ_TYPE_GET_LIST)
                                .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }

  getListObjTypesByObjClass(objClassCode: string, searchKey: string){
    const url = this.mainApiUrl.concat(CommonCatsConst.OBJ_TYPE_GET_LIST_BY_OBJ_CLASSS)
                                .replace("{objClass}", objClassCode)
                                .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }

  getListPagingObjTypes(page: number, sort: string, sortBy:string, searchKey: string){
    const url = this.mainApiUrl.concat(CommonCatsConst.OBJ_TYPE_GET_LIST_PAGING)
                                .replace("{page}", "" + page)
                                .replace("{sort}", sort)
                                .replace("{sortBy}", sortBy)
                                .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }
  //#endregion

  //#region Func Module
  getListFuncModule(){
    const url = this.systemApiUrl.concat(SystemConst.FUNC_MODULE_GET_LIST);    
    return this.http.get(url);
  }

  updateFuncModule(id, data) {
    const url = this.systemApiUrl.concat(SystemConst.FUNC_MODULE_CRUD).replace("{id}", id);    
    return this.http.put(url, data);
  }
  //#endregion

  //#region Sidebar
  findSubmenu(code: string, routing: string){
    let parent, child;
    for (let item of this.sidebarMenu) {
      if (item.code === code) {
        parent = item;
        let menu = item.childrend;
        for (let submenu of menu) {
          if (submenu.layerUrl) {
            if (submenu.layerUrl.includes("/log/system")) submenu.layerUrl = submenu.layerUrl.replace("/log/system", "/log"); //special for log which have 2 tabs system and database
            if (routing.includes(submenu.layerUrl)) {
              child = submenu;
              break;
            }
          }
        }
      }
    }
    return {
      menu: parent,
      submenu: child
    };
  }

  updateSidebarMenu(id, data) {
    const url = this.mainApiUrl.concat(MainConst.SIDEBAR_MENU_CRUD).replace("{id}", id);    
    return this.http.put(url, data);
  }

  createSidebarMenu(data) {
    const url = this.mainApiUrl.concat(MainConst.SIDEBAR_MENU_CRUD).replace("/{id}", "");    
    return this.http.post(url, data);
  }

  uploadFile(file: any, fileType: string){
    const formData = new FormData();
    formData.append('files', file);
    formData.append('FileType', fileType);
    
    const url = this.systemApiUrl.concat(SystemConst.UPLOAD_NEW);
    return this.http.post(url, formData);
  }

  //#region Administrative
  getListProvince(sort: string, sortBy: string, searchKey: string){
    const url = this.mainApiUrl.concat(CommonCatsConst.PROVINCE_LIST)
                              .replace("{sort}", sort)
                              .replace("{sortBy}", sortBy)
                              .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }

  getListPagingProvince(page: number, pageSize: number, sort: string, sortBy: string, searchKey: string){
    const url = this.mainApiUrl.concat(CommonCatsConst.PROVINCE_LIST_PAGING)
                              .replace("{page}", page + "")
                              .replace("{pageSize}", pageSize + "")
                              .replace("{sort}", sort)
                              .replace("{sortBy}", sortBy)
                              .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }

  getListDistrict(provinceCode: string, sort: string, sortBy: string, searchKey: string){
    const url = this.mainApiUrl.concat(CommonCatsConst.DISTRICT_LIST)
                              .replace("{provinceCode}", provinceCode)
                              .replace("{sort}", sort)
                              .replace("{sortBy}", sortBy)
                              .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }

  getListPagingDistrict(provinceCode: string, page: number, pageSize: number, sort: string, sortBy: string, searchKey: string){
    const url = this.mainApiUrl.concat(CommonCatsConst.DISTRICT_LIST_PAGING)
                              .replace("{provinceCode}", provinceCode)
                              .replace("{page}", page + "")
                              .replace("{pageSize}", pageSize + "")
                              .replace("{sort}", sort)
                              .replace("{sortBy}", sortBy)
                              .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }

  getListWard(districtCode: string, sort: string, sortBy: string, searchKey: string){
    const url = this.mainApiUrl.concat(CommonCatsConst.WARD_LIST)
                              .replace("{districtCode}", districtCode)
                              .replace("{sort}", sort)
                              .replace("{sortBy}", sortBy)
                              .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }

  getListPagingWard(districtCode: string, page: number, pageSize: number, sort: string, sortBy: string, searchKey: string){
    const url = this.mainApiUrl.concat(CommonCatsConst.WARD_LIST_PAGING)
                              .replace("{districtCode}", districtCode)
                              .replace("{page}", page + "")
                              .replace("{pageSize}", pageSize + "")
                              .replace("{sort}", sort)
                              .replace("{sortBy}", sortBy)
                              .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }
  //#endregion

  //#region Deparment
  getListDeparment(sort: string, sortBy: string, searchKey: string){
    const url = this.mainApiUrl.concat(CommonCatsConst.DEPARTMENT_LIST)
                              .replace("{sort}", sort)
                              .replace("{sortBy}", sortBy)
                              .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }
  //#endregion

  //#region Position
  getListPosition(sort: string, sortBy: string, searchKey: string){
    const url = this.mainApiUrl.concat(CommonCatsConst.POSITION_LIST)
                              .replace("{sort}", sort)
                              .replace("{sortBy}", sortBy)
                              .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }
  //#endregion

  //#region Render layout
  getFieldsDropdownData(code: string, parentId: string){
    let url = this.mainApiUrl.concat(MainConst.FIELDS_GET_DROPDOWN).replace('{code}', code);
    if (parentId) url = url.replace('{parent_id}', parentId);
    else url = url.replace('/{parent_id}', "");

    return this.http.get(url);
  }

  getDataForSelectboxByURL(apiUrl: string){    
    let url = apiUrl.replace('{MainApiURL}/', this.mainApiUrl).replace('{SystemApiURL}/', this.systemApiUrl).replace('{MainTrackingURL}/', this.trackingApiUrl);
    return this.http.get(url);
  }

  getSelectboxWithFieldBool(){    
    let data = [
      {name: this.translate.instant("geozone_permit_permit_inactive"), code: false}, 
      {name: this.translate.instant("geozone_permit_permit_active"), code: true},        
    ];
    return data;
  }
  //#endregion
}
