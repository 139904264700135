<div class="container-scroller">
    <!-- <app-navbar *ngIf="showNavbar"></app-navbar> -->
    <div class="container-fluid page-body-wrapper">
        <button  *ngIf="showSidebar && isLoginUser" class="btn btn-secondary position-absolute toggle-button" [ngClass]="{'left-260px': !iconOnlyToggled, 'left-70px': iconOnlyToggled}" (click)="toggleSidebar()">
            <i *ngIf="iconOnlyToggled" class="mdi mdi-menu-right-outline" placement="right" ngbTooltip="{{'global_expand' | translate}}"></i>
            <i *ngIf="!iconOnlyToggled" class="mdi mdi-menu-left-outline"></i>
        </button>
        <button *ngIf="showSidebar && isLoginUser" class="btn btn-secondary position-fixed menu-toggle-button" (click)="toggleOffcanvas()">
            <i class="mdi mdi-menu"></i>
        </button>
        <app-sidebar *ngIf="showSidebar && isLoginUser"></app-sidebar>
        <div class="main-panel" [ngClass]="{ 'main-panel-only' : !showSidebar }">
            <div [ngClass] ="'main-content'">
                <app-spinner *ngIf="isLoading"></app-spinner>
                <div contentAnimate *ngIf="!isLoading" class="h-100">
                    <router-outlet></router-outlet>
                </div>
            </div>
            <!-- <app-footer *ngIf="showFooter"></app-footer> -->
        </div>
    </div>
</div>