<ng-template #viewAttachFileModal let-modal>
    <div class="modal-header bg-white">
        <h5 class="modal-title">{{title | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body bg-white" *ngIf="fileType == 'file'">
        <div *ngIf="isLoading" class="bg-spinner">
            <div class="spinner text-primary"></div>
        </div>
        <ngx-doc-viewer  *ngIf="srcUrl" [url]="srcUrl" viewer="google" class="file-viewer" (loaded)="contentLoaded()"></ngx-doc-viewer>
        <div *ngIf="!srcUrl" class="file-viewer">
            <p class="not-found-content mb-1"><i>{{notFoundContent | translate}}</i></p>
        </div>
    </div>
    <div class="modal-body bg-white text-center" *ngIf="fileType == 'image'">
        <img *ngIf="srcUrl" src="{{srcUrl}}" class="img-fluid" alt="">
        <div *ngIf="!srcUrl" class="file-viewer">
            <p class="not-found-content mb-1"><i>{{notFoundContent | translate}}</i></p>
        </div>
    </div>
    <div class="modal-footer bg-white">
        <button type="button" class="btn btn-secondary p-2" (click)="close()">{{'global_close' | translate}}</button>
    </div>
</ng-template>