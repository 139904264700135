import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AutofocusDirective } from './directives/autofocus.directive';
import { TooltipDirective } from './directives/tooltip.directive';
import { ColorDirective } from './directives/color.directive';
import { SafePipe } from '@shared/pipes/safe.pipe';
import { ObjFieldNamePipe } from './pipes/obj-field-name.pipe';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [AutofocusDirective, TooltipDirective, ColorDirective, SafePipe, ObjFieldNamePipe],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule
  ],
  exports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    AutofocusDirective,
    TooltipDirective,
    ColorDirective,
    SafePipe,
    ObjFieldNamePipe
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    }
  }
}
