import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
import { ObjClassModel } from '@shared/models/main.model';
import { PermissionType } from '@core/services/root/permission/enums/permission.enum';
import { Basic } from '@core/lib/basic';

@Component({
  selector: 'obj-class-menu',
  templateUrl: './obj-class-menu.component.html',
  styleUrls: ['./obj-class-menu.component.scss']
})
export class ObjClassMenuComponent implements OnInit {

  @Output() onSelectedObjClass = new EventEmitter<any>();
  @Output() onChangePage = new EventEmitter<number>();
  @Output() onSearch = new EventEmitter<object>();
  
  // Menu title
  @Input() showTitle: boolean; // default: false
  @Input() title: string; // default: "global_list" - check dictionary
  // Search
  @Input() showSearch: boolean; // default: true
  @Input() searchKey: string;
  // Input Data
  @Input() data: ObjClassModel[]; //all data if not use pagination, showing data is use pagination
  @Input() totalData: number; //total data to show pagination
  // Pagination
  @Input() showPagination: boolean; // default: True
  @Input() page: number; // default: 1
  @Input() pageSize: number; // default: 10
  
  // Icon
  @Input() icon: string; //'current': ObjClass Icon, 'default': default of this component, 'custom': custom by customIconPath, 'none': not show icon; default: 'current'
  @Input() customIconPath: string; //'current': ObjClass Icon, 'default': default of this component, 'custom': custom by customIconPath

  // Selection on load menu
  @Input() selectedByCode: string;
  @Input() showCodeAsRowSubtitle: boolean; // default: true

  scrollbarConfig: PerfectScrollbarConfigInterface =  { suppressScrollX: false };
  selectedObjClassId: string = "";
  selectedObjClass: ObjClassModel = new ObjClassModel;
  
  //sort
  defaultIconUrl: string = "assets/images/icons/config.png";

  //permission
  _permissionViewType: PermissionType = PermissionType.view;

  constructor(public translate: TranslateService) { 
      if (!this.title) this.title = this.translate.instant("global_list");
      if (typeof this.showTitle !== 'boolean') this.showTitle = false;
      if (typeof this.showCodeAsRowSubtitle !== 'boolean') this.showCodeAsRowSubtitle = true;
      if (typeof this.showSearch !== 'boolean') this.showSearch = true;
      if (typeof this.showPagination !== 'boolean') this.showPagination = true;
      if (!this.icon) this.icon = 'current';
      if (this.icon == 'custom' && !this.customIconPath) this.customIconPath = this.defaultIconUrl;
      if (!this.page) this.page = 1;
      if (!this.pageSize) this.pageSize = 10;
      if (!this.selectedByCode) this.selectedByCode = "";
    }

  ngOnInit(): void {
    this.checkAndSelection();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) this.data = changes.data.currentValue; 
    this.checkAndSelection();
  }

  checkActiveLayerlist(layer: ObjClassModel){
    if (this.selectedObjClass) {
      if (layer.subCode) {
        if (this.selectedObjClass.code == layer.code && this.selectedObjClass.subCode == layer.subCode) return true;
        else return false;
      } else {
        if (this.selectedObjClass.code == layer.code) return true;
        else return false;
      }
    } else return false;
  }

  changePage($event) {
    this.page = $event as number;
    this.onChangePage.emit(this.page);
  }

  onSelect(layer: ObjClassModel){
    this.selectedObjClass = layer;
    this.selectedObjClassId = layer.id;
    //publish event
    let data = {
      objClass: this.selectedObjClass
    };
    this.onSelectedObjClass.emit(data);
  }

  search($event){
    this.page = 1;
    this.searchKey = $event.search_string;
    this.onSearch.emit({
      search_string: this.searchKey
    });
  }

  checkAvatarUrl(str: string){
    return Basic.isImage(str);
  }

  checkAndSelection(){
    if (this.selectedByCode) {
      for (let oc of this.data) {
        if (oc.code === this.selectedByCode) {
          this.selectedObjClass = oc;
          this.selectedObjClassId = oc.id;
          this.onSelect(oc);
        }
      }
    } else {
      if (this.data[0] && !this.selectedObjClass.id) {
        this.selectedObjClass = this.data[0];
        this.selectedObjClassId = this.selectedObjClass?.id;
        this.onSelect(this.selectedObjClass);
      }
    }
  }
}
