import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BasicService } from '@core/services/root/basic/basic.service';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() totalData: number;
  @Input() currentPageData: any[];
  @Input() pageSize: number;
  @Input() pageNumber: number;
  @Input() showTitle: boolean;
  @Output() pageChange = new EventEmitter<any>();

  constructor(private basicService: BasicService) { }

  ngOnInit(): void {
    if (typeof this.showTitle != "boolean") this.showTitle = false;
  }

  showPaginationTitle(){
    return this.basicService.setPaginationTitle(this.totalData, this.currentPageData.length, this.pageSize, this.pageNumber);
  }

  changePage($event){
    this.pageChange.emit($event);
  }

}
