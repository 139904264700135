import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Constant } from '@core/constant/api-system/Constant';
import { UserPermisisonModel} from '@shared/models/pages/system-management/permission.model';
import { ObjClassModel } from '@shared/models/main.model';
import { CacheAll } from '@core/lib/cache';
import { CacheConst } from '@core/constant/shared/system.const';
import { ApiReturnModel } from '@shared/models/global.model';
import { AuthService } from '@core/services/root/auth/auth.service';
import { PermissionType } from '../enums/permission.enum';
import { SystemConst } from '@core/constant/api-main/system.const';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionService {
  /**Kiểm tra nếu không phải ROLE ADMIN thì mới sử dụng Permission Service*/ 
  systemApiUrl = environment.SYSTEM_API_URL;
  mainApiUrl = environment.MAIN_API_URL;
  userId: string = "";
  _isAdmin: boolean = false;
  _currentRole: string = "";
  _currentPermission: UserPermisisonModel[] = [];
  _isGetPerm: boolean = false;

  constructor(private http: HttpClient, private authService: AuthService) { 
    this.userId = CacheAll.getCookie(CacheConst.USER_ID);
    this._isAdmin = this.authService.isAdmin();
    this._currentRole = this.authService.getCurrentRole();
    this.getUserPermissionByToken().subscribe(rs => {
      let result = rs as ApiReturnModel;
      if (result.code == 0) {
        this._isGetPerm = true;
        this._currentPermission = result.data;
      }
    })
  }

  isAdmin(){
    return this._isAdmin;
  }

  getCurrentRole(){
    return this._currentRole;
  }

  getUserPermissionByToken(){
    const url = this.systemApiUrl.concat(Constant.AUTH_USER_PERMISSION);
    return this.http.get(url);
  }

  /**Kiểm tra nếu tồn tại _currentPermission thì sẽ thực hiện kiểm tra Permisison còn không sẽ call API sau đó mới kiểm tra Permission*/ 
  checkPermission(objClass: ObjClassModel, type: PermissionType){
    if (this._isAdmin) return true;
    else if (this._currentRole && this._currentRole != "ADMIN") {
      if (this._currentPermission.length == 0 && !this._isGetPerm) {
        this.getUserPermissionByToken().subscribe(rs => {
          let result = rs as ApiReturnModel;
          if (result.code == 0) {
            this._isGetPerm = true;
            this._currentPermission = result.data;
            let hasPerm = this._hasPerm(this._currentPermission, objClass, type);
            return hasPerm;
          }
        }, error => {
          return false;
        })
      } else {
        let hasPerm = this._hasPerm(this._currentPermission, objClass, type);
        return hasPerm;
      }
    }
  }

  /** 
   * Kiểm tra nếu tồn tại _currentPermission thì sẽ thực hiện kiểm tra Permisison còn không sẽ call API sau đó mới kiểm tra Permission. 
   * Trả về kết quả Permssion theo Callback func 
  */ 
  checkPermissionNew(objClass: ObjClassModel, type: PermissionType, callback){
    if (this._isAdmin) callback(true);
    else if (this._currentRole && this._currentRole != "ADMIN") {
      if (this._currentPermission.length == 0 && !this._isGetPerm) {
        this.getUserPermissionByToken().subscribe(rs => {
          let result = rs as ApiReturnModel;
          if (result.code == 0) {
            this._isGetPerm = true;
            this._currentPermission = result.data;
            let hasPerm = this._hasPerm(this._currentPermission, objClass, type);
            callback(hasPerm)
          }
        }, error => {
          return false;
        })
      } else {
        let hasPerm = this._hasPerm(this._currentPermission, objClass, type);
        callback(hasPerm);
      }
    }
  }
  /** 
   * Xây dựng vòng lặp để lấy quyền phù hợp với ObjClass truyền vào. 
   * Thực hiện merge quyền (nếu người dùng được phân vào nhiều nhóm quyền)
   * Kiểm tra PermType (C-R-U-D)
   * Kiểm tra Specialized Role (LGSP)
   * Return kết quả type Boolean
  */ 
  _hasPerm(currentPermission: UserPermisisonModel[], objClass: ObjClassModel, type: PermissionType) :boolean{
    let allValidPerm = [] as UserPermisisonModel[];
    let permToCheck;
    let hasPerm;
    for (let perm of currentPermission) {
      if (perm.objClass && (perm.objClass.id === objClass.id)) allValidPerm.push(perm);
    }
    if (allValidPerm.length > 0) {
      permToCheck = this.mergePermission(allValidPerm);
      if (permToCheck) {
        hasPerm = this.checkByType(permToCheck, type);
      } else {
        hasPerm = false;
      }
    } else {
      hasPerm = false;
    }
    //--- Specialized for "LGSP" Role --- 
    if (this._currentRole == "LGSP" && type != PermissionType.view) hasPerm = false;
    return hasPerm;
  }

  checkByType(perm: UserPermisisonModel, type: PermissionType){
    switch (type) {
      case "view":
        if (perm.allowView === 1) return true;
        else return false;
      case "update":
        if (perm.allowUpdate === 1 && perm.allowView === 1) return true;
        else return false;
      case "create":
        if (perm.allowCreate === 1 && perm.allowView === 1) return true;
        else return false;
      case "delete":
        if (perm.allowDelete === 1 && perm.allowView === 1) return true;
        else return false;
      default:
        return false;
    }
  }

  mergePermission(perms: UserPermisisonModel[]){
    let returnData = new UserPermisisonModel;
    for (let perm of perms){
      if (returnData) {
        returnData.allowView = returnData.allowView || perm.allowView;
        returnData.allowCreate = returnData.allowCreate || perm.allowCreate;
        returnData.allowUpdate = returnData.allowUpdate || perm.allowUpdate;
        returnData.allowDelete = returnData.allowDelete || perm.allowDelete;
      } else {
        returnData = perm;
      }
    }
    return returnData;
  }

  /** 
   * Lấy dữ liệu ObjClass type DataLayers, với ứng với các quyền C-R-U-D
   * Return kết quả type Boolean dạng Group Layers, cần thực hiện lọc để lấy danh sách DataLayers mà user có quyền
  */ 
  getDataLayersWithPermission(sort: string, sortBy: string, searchKey:string, action: PermissionType) {
    const url = this.mainApiUrl.concat(SystemConst.LAYER_GROUP_GET_LIST_PERM)
                              .replace("{sort}", sort)
                              .replace("{sortBy}", sortBy)
                              .replace("{searchKey}", searchKey)
                              .replace("{action}", action);
    return this.http.get(url);
  }

  /** 
   * Lấy dữ liệu ObjClass type Sidebar Menu
   * Return kết quả type Boolean dạng Group Sidebar Menu, cần thực hiện lọc để lấy danh sách Menu mà user có quyền
  */ 
  getSidebarMenuWithPermission(sort: string, sortBy: string, searchKey:string) {
    const url = this.mainApiUrl.concat(SystemConst.SYSTEM_SIDEBAR_GET_LIST)
                              .replace("{sort}", sort)
                              .replace("{sortBy}", sortBy)
                              .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }

}
