<ng-template #iframeModal let-modal>
    <div class="modal-header bg-white">
        <h5 class="modal-title">{{title | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body bg-white">
        <!-- <div *ngIf="isLoading" class="bg-spinner">
            <div class="spinner text-primary"></div>
        </div> -->
        <iframe *ngIf="srcUrl" class="responsive-iframe" [src]="srcUrl | safe"></iframe>
        <div *ngIf="!srcUrl" class="file-viewer">
            <p class="not-found-content mb-1"><i>{{notFoundContent | translate}}</i></p>
        </div>
    </div>
    <div class="modal-footer bg-white">
        <button type="button" class="btn btn-secondary p-2" (click)="close()">{{'global_close' | translate}}</button>
    </div>
</ng-template>