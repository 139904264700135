import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ObjClassModel, } from '@shared/models/main.model';
import { ModuleEnum } from '@shared/enums/module.enum';
import { SidebarMenuModel } from '@shared/models/sidebar.model';
import { BreadCrumbModel } from '@shared/models/global.model';
import { AppPageRoutingEnum } from '@shared/enums/routing.enum';
import { Title } from "@angular/platform-browser";
import { TranslateService } from '@ngx-translate/core';
import { MenuConfigService } from '@core/services/pages/system-management/menu-config.service';

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {

  @Input() isMainPage: boolean;
  @Input() subPageName: string;
  @Output() backToPreviousPage = new EventEmitter<object>();

  module: ModuleEnum;
  pathname: string = window.location.pathname;
  pageTitle: string = "";
  pageIcon: string = "";
  breadcrumb: BreadCrumbModel[] = [];
  
  constructor(private menuConfigService: MenuConfigService, private titleService:Title, private translate: TranslateService) { 
    if (typeof this.isMainPage != "boolean") this.isMainPage = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.subPageName && changes.subPageName.currentValue) {
      this.subPageName = changes.subPageName.currentValue; 
      this.initBreadcrumb();
    }
  }

  ngOnInit(): void {
    this.initBreadcrumb();
  }

  initBreadcrumb(){
    if (this.pathname.includes(AppPageRoutingEnum.SYSTEM_MANAGEMENT)) this.module = ModuleEnum.SYSTEM_MANAGEMENT;
    if (this.pathname.includes(AppPageRoutingEnum.HR_MANAGEMENT)) this.module = ModuleEnum.HR_MANAGEMENT;
    if (this.pathname.includes(AppPageRoutingEnum.CATEGORY_MANAGEMENT)) this.module = ModuleEnum.CATEGORY_MANAGEMENT;
    if (this.pathname.includes(AppPageRoutingEnum.WMS)) this.module = ModuleEnum.WMS;

    this.menuConfigService.getChildrenByCodeAndRouting(this.module, this.pathname, (data) => {
      let submenu = data.submenu as ObjClassModel;
      let menu = data.menu as SidebarMenuModel;
      if (submenu && menu) {
        this.pageTitle = submenu.name;
        this.pageIcon = submenu.iconUrl;
        if (this.isMainPage) {
          this.breadcrumb = [{
            name: menu.name,
            level: 0,
            routerLink: ""
          },{
            name: submenu.name,
            level: 1,
            routerLink: ""
          }]
          this.titleService.setTitle(`${submenu.name} - ${menu.name} - ${this.translate.instant("global_app_name")}`);
        } else {
          this.breadcrumb = [{
            name: menu.name,
            level: 0,
            routerLink: ""
          },{
            name: submenu.name,
            level: 1,
            routerLink: submenu.layerUrl
          },{
            name: this.subPageName,
            level: 2,
            routerLink: ""
          }];
          this.titleService.setTitle(`${this.translate.instant(this.subPageName)} - ${submenu.name} - ${menu.name} - ${this.translate.instant("global_app_name")}`);
        }
      }
    });
  }

  back(){
    this.backToPreviousPage.emit();
  }

}
