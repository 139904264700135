<ng-template #d2dSelectionModal let-modal>
    <div class="modal-header bg-white">
        <h5 class="modal-title">{{title | translate}}</h5>        
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    <div class="modal-body bg-white">
        <p class="mt-2 mb-2 title">{{"global-from_date" | translate}}<required></required> :</p>
        <div class="mb-2 custom-file">
            <input type="date" class="form-control mb-2 p-0 pl-4" placeholder="{{'stream-export-report-fromdate' | translate}}" [(ngModel)]="fromDate">
        </div>
        <p class="mt-2 mb-2 title">{{"global-to_date" | translate}}<required></required> :</p>
        <div class="mb-2 custom-file">
            <input type="date" class="form-control mb-2 p-0 pl-4" placeholder="{{'stream-export-report-todate' | translate}}" [(ngModel)]="toDate">
        </div>
        <div *ngIf="fromDate && toDate && (fromDate > toDate)" class="error-status">
            {{"validate-fromdate-todate" | translate}}
        </div>
    </div>
    <div class="modal-footer bg-white">
        <button type="button" class="btn btn-success mr-2 p-2" [disabled]="isDisabledBtn()" (click)="submit()">{{'global_submit' | translate}}</button>
        <button type="button" class="btn btn-secondary p-2" (click)="modal.close('Save click')">{{'global_cancel' | translate}}</button>
    </div>
</ng-template>