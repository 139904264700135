import { Pipe, PipeTransform } from '@angular/core';
import { ObjFieldModel } from '../models/main.model';

@Pipe({
  name: 'objFieldName'
})
export class ObjFieldNamePipe implements PipeTransform {

  transform(code: string, objFields: ObjFieldModel[]): string {
    let objField = new ObjFieldModel;
    objField = objFields.find((_oF) => _oF.code.toLowerCase() === code.toLowerCase());
    return (objField && objField.name) ? objField.name : "";
  }

}
