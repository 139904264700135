<div class="filter-widget" *ngIf="filterFields.length">
    <div class="row">
        <ng-container *ngFor="let field of filterFields; trackBy: trackById">
            <div class="col-lg-3 col-xs-4 col-6 p-2" *ngIf="field.componentUI">
                <input *ngIf="field.componentUI == componentUiEnum.INPUT_TEXT" 
                        [(ngModel)]="field.value" placeholder="{{field.name}}" class="form-control mb-2" type="text">
                <input *ngIf="field.componentUI == componentUiEnum.CALENDAR_DATE" 
                        [(ngModel)]="field.value" type="date" class="form-control mb-2 pt-0 pb-0">
                <input *ngIf="field.componentUI == componentUiEnum.CALENDAR_DATETIME" 
                        [(ngModel)]="field.value" type="datetime-local" class="form-control mb-2 pt-0 pb-0">
                <input *ngIf="field.componentUI == componentUiEnum.CALENDAR_TIME" 
                        [(ngModel)]="field.value" type="time" class="form-control mb-2 pt-0 pb-0">
                <div *ngIf="field.componentUI == componentUiEnum.COMBOBOX_SINGLE" class="mb-2"> 
                    <searchable-selectbox [selection]="field.value" [url]="field.apiUrl"  [title]="field.name" (onSelect)="field.value = $event.data;"></searchable-selectbox>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="text-right">
        <ng-container *ngFor="let field of filterFields; trackBy: trackById">
            <label *ngIf="field.value" class="badge badge-outline-danger cursor-pointer mr-2" [tooltip]="'global_unfilter' | translate" (click)="field.value = null; filter();">
                <i class="mdi mdi-window-close"></i> {{ (field.value && field.value.name) ? (field.value.name | translate) : (field.value | translate)}}
            </label>
        </ng-container>
        <button class="btn btn-info" (click)="filter()"><i class="mdi mdi-filter-outline"></i> {{"global_filter" | translate}}</button>
        <button class="btn btn-light" (click)="cancel()"><i class="mdi mdi-cancel"></i> {{"global_cancel" | translate}}</button>
    </div>
</div>
