import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  @Output() onClick = new EventEmitter<string>();
  @Input() type:string;
  @Input() title:string;
  @Input() titleStyle:string;
  @Input() content:string;
  @Input() contentStyle:string;
  @Input() centerPage:boolean;
  @Input() size: string;

  @ViewChild('confirmModal') modal: any;
  
  closeResult: string;
  modalReference: NgbModalRef;
  confirmData: any;
  _types: string[] = ["normal", "insert", "update", "delete"];
  isLoading: boolean = false;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
    if (!this.type) this.type = 'normal';
    if (!this.centerPage) this.centerPage = false;
    if (!this.size) this.size = 'md'; //size: sm, md, lg
  }

  clickLink(){
    this.isLoading = true;
    this.onClick.emit(this.confirmData);
  }

  open(data) {
    this.isLoading = false;
    this.modalReference = this.modalService.open(this.modal, {
      ariaLabelledBy: 'modal-basic-title', 
      centered: this.centerPage,
      size: this.size
    });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.confirmData = data;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  close(){
    this.modalReference.close();
  }
}
