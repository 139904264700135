<ng-template #changeAvtModal let-modal>
    <!-- Modal body -->
    <div class="modal-header m-auto">
      <span *ngIf="isDisabled" class="mdi mdi-file-image text-secondary icon-img"></span>
      <img *ngIf="!isDisabled" src="{{imgUrl}}" alt="" class="rounded-circle image" width="150">
      <img  id="preview" alt="" class="rounded-circle image">
    </div>
    <div class="modal-body  text-center">
      <b class="mb-2">{{title | translate}}</b>
      <p class="sub-title mt-3">{{subtitle | translate}}</p>
      <div class="custom-file">
        <input type="file" class="form-control mb-2 custom-file-input" id="fileInput" (change)="onFileSelected($event)" accept="image/png, image/gif, image/jpeg">
        <label class="custom-file-label" for="fileInput">{{"user-choose_file" | translate}}</label>
    </div>
      <div class="mx-auto">
        <button type="button" class="btn btn-success mr-2 p-2" [disabled]="isDisabled" (click)="clickLink()">{{'global_submit' | translate}}</button>
        <button type="button" class="btn btn-secondary p-2" (click)="modal.close('Save click')">{{'global_cancel' | translate}}</button>
      </div>
    </div>
</ng-template>