import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiReturnModel } from '@shared/models/global.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Basic } from '@core/lib/basic';
import { AuthService } from '@core/services/root/auth/auth.service';
import { MainService } from '@core/services/root/main/main.service';

@Component({
  selector: 'upload-file-modal',
  templateUrl: './upload-file-modal.component.html',
  styleUrls: ['./upload-file-modal.component.scss']
})
export class UploadFileModalComponent implements OnInit {

  @Output() onUploadSuccess = new EventEmitter<object>();
  @Input() centerPage: boolean;
  @Input() size: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() fileType: string;
  
  @ViewChild('uploadFileModal') modal: any;
  
  closeResult: string;
  modalReference: NgbModalRef;
  confirmData: any;
  isDisabled: boolean = true;
  fileUpload: any;
  uploadFileType: string = "";
  uploadFileName: string = "";

  bindingData: any;

  constructor(public authService: AuthService, private modalService: NgbModal, public mainService: MainService, public toast: ToastrService, public translate: TranslateService) { 
   
  }

  ngOnInit(): void {
    if (!this.centerPage) this.centerPage = false;
    if (!this.size) this.size = 'md'; //size: sm, md, lg
    if (!this.title) this.title = "global_upload_file-title";
    if (!this.subtitle) this.subtitle = "global_upload_file-subtitle";
    if (!this.fileType) this.subtitle = "FileUpload";
  }

  onFileSelected($event){
    this.isDisabled = true;
    let file = $event.target.files[0] as File;
    //check if upload file
    if (file) this.fileUpload = null;
    else if (!file && this.fileUpload) file = this.fileUpload;
    //check file exist
    if (file) {
      this.uploadFileType = file.type;
      let size = file.size;
      //rename input
      this.uploadFileName = Basic.removeAllSpaces(file.name);
      let nextSibling = $event.target.nextElementSibling;
          nextSibling.innerText = this.uploadFileName;
      if (this.uploadFileType === "application/pdf" || this.uploadFileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || this.uploadFileType === "application/msword") {
          this.isDisabled = false;
          //convert new file
          let blob = file.slice(0, size, this.uploadFileType); 
          this.fileUpload = new File([blob], this.uploadFileName, {type: this.uploadFileType});
      }
      else this.toast.error(this.translate.instant("global_upload_file-subtitle"));
    }
  }

  clickLink(){
    this.uploadFile(this.fileUpload, this.fileType);
  }

  uploadFile(file, fileType:string){
    this.mainService.uploadFile(file, fileType).subscribe(rs => {
      let result = rs as ApiReturnModel;
      if (result.code == 0) {
        let fileUrl = result.data[0].downloadFileURI;
        if (this.bindingData) {
          this.onUploadSuccess.emit({
            data: this.bindingData,
            fileUrl: fileUrl
          })
        }
        else this.onUploadSuccess.emit(fileUrl);
      } else {
        if (!this.authService.checkInvalidResponse(result)) this.toast.error(result.message);
      }
    }, error => {
      this.toast.error(error.message);
      this.onUploadSuccess.emit(null);
    })
  }

  open(data?: any) {
    this.modalReference = this.modalService.open(this.modal, {
      ariaLabelledBy: 'modal-basic-title', 
      centered: this.centerPage,
      size: this.size
    });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.isDisabled = true;
    this.bindingData = data ? data : null;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  close(){
    this.modalReference.close();
  }
}
