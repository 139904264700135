import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Basic } from '@core/lib/basic';
import {NgbModal, ModalDismissReasons, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'confirm-input-modal',
  templateUrl: './confirm-input-modal.component.html',
  styleUrls: ['./confirm-input-modal.component.scss']
})
export class ConfirmInputModalComponent implements OnInit {

  @Output() onClick = new EventEmitter<object>();
  @Input() type:string;
  @Input() title:string;
  @Input() titleStyle:string;
  @Input() content:string;
  @Input() contentStyle:string;
  @Input() centerPage:boolean;
  @Input() size: string;
  @Input() inputValue: string;
  @Input() inputType: string
  firstInput: string = "";

  @ViewChild('confirmModal') modal: any;
  
  closeResult: string;
  modalReference: NgbModalRef;
  confirmData: any;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
    if (!this.type) this.type = 'normal'; // type: normal, insert, update, delete
    if (!this.centerPage) this.centerPage = false;
    if (!this.size) this.size = 'md'; //size: sm, md, lg
    if (!this.inputValue) this.inputValue = '';
    if (!this.inputType) this.inputType = 'input-text'; //input-text | textarea
    this.firstInput = this.inputValue;
  }

  ngOnChanges(): void {
    this.firstInput = this.inputValue;
  }

  clickLink(){
    let returnData = {
      data: this.confirmData,
      inputValue: Basic.trimAndRemoveDoubleSpace(this.inputValue)
    }
    this.onClick.emit(returnData);
  }

  open(data) {
    this.modalReference = this.modalService.open(this.modal, {
      ariaLabelledBy: 'modal-basic-title', 
      centered: this.centerPage,
      size: this.size
    });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.inputValue = this.firstInput;
    this.confirmData = data;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  close(){
    this.modalReference.close();
  }

}
