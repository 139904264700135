<ng-template #confirmModal let-modal>
  <div class="modal-header m-auto" *ngIf="type">
    <span *ngIf="type == 'normal'" class="mdi mdi-information-outline text-secondary icon-img"></span>
    <span *ngIf="type == 'insert'" class="mdi mdi-shape-circle-plus text-primary icon-img"></span>
    <span *ngIf="type == 'update'" class="mdi mdi-progress-check text-success icon-img"></span>
    <span *ngIf="type == 'delete'" class="mdi mdi-delete-empty-outline text-danger icon-img"></span>
    <span *ngIf="!_types.includes(type)" class="{{type}} icon-img"></span>
  </div>
  <div class="modal-body">
    <b [style]="titleStyle">{{title | translate}}</b>
    <p class="sub-title mt-2" [style]="contentStyle">{{content | translate}}</p>
  </div>
  <div class="modal-footer footer-confirm">
    <span *ngIf="isLoading" class="spinner-border spinner-border-sm text-primary mt-2"></span>
    <button type="button" class="btn btn-light p-2" (click)="modal.close('Save click')">{{'global_cancel' | translate}}</button>
    <button type="button" class="btn btn-success mr-2 p-2" (click)="clickLink()">{{'global_submit' | translate}}</button>
  </div>
</ng-template>