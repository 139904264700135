import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appColor]'
})
export class ColorDirective {

  @Input() appColor = ''; 

  constructor(private el: ElementRef) {
  }

  ngOnInit()
  {
      if (this.appColor)
      {
        this.el.nativeElement.style.backgroundColor = this.appColor;
        this.el.nativeElement.style.border = "none";
      }
  }
  
}
