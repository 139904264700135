import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PubSubService } from '@core/services/root/pub-sub/pub-sub.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthService } from '@core/services/root/auth/auth.service';
import { ApiReturnModel } from '@shared/models/global.model';
import { ToastrService } from 'ngx-toastr';
import { CacheAll } from '@core/lib/cache';
import { TTL, CacheConst } from '@core/constant/shared/system.const';
import { Base64 } from '@core/lib/encode';
import { environment } from '@env/environment';
import { UserDataModel } from '@shared/models/auth.model';
import { Subscription } from 'rxjs';
import { UserPermissionService } from '@core/services/root/permission/services/user-permission.service';
import { AppPageRoutingEnum } from '@shared/enums/routing.enum';
import { UserRoutingEnum } from '@shared/enums/pages/user-routing.enum';
import { MenuConfigService } from '@core/services/pages/system-management/menu-config.service';
import { SidebarMenuGroupModel } from '@shared/models/pages/system-management/menu-config.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public uiBasicCollapsed = false;
  public samplePagesCollapsed = false;
  appVersion: string = environment.VERSION;
  activeUrl: string = "";
  scrollbarConfigSidebar: PerfectScrollbarConfigInterface = {};
  avatarUrl: string;
  isAdmin: boolean = false;;
  userLastname: string = "H";
  userFirstname: string = "T";
  isCollapsedSidebar: boolean = false;
  currentUser: UserDataModel = new UserDataModel;

  subscription: Subscription;
  subsFromConnectionSuccess: Subscription;
  subsFromAvatarChange: Subscription;
  subsFromCollapseMenu: Subscription;

  searchKey: string = "";

  hoverName: string = "";
  homeUrl: string = `/${AppPageRoutingEnum.HOME}`;
  sidebarMenuData: SidebarMenuGroupModel[] = [];
  showingSidebarMenuData: SidebarMenuGroupModel[] = [];
  //
  userProfileUrl: string = `/${AppPageRoutingEnum.USER_PROFILE}/${UserRoutingEnum.PROFILE}`;
  userUpdateUrl: string = `/${AppPageRoutingEnum.USER_PROFILE}/${UserRoutingEnum.UPDATE_INFOR}`;
  userChangePassUrl: string = `/${AppPageRoutingEnum.USER_PROFILE}/${UserRoutingEnum.CHANGE_PASS}`;
  checkValidPage: string[] = [
    `/${AppPageRoutingEnum.SYSTEM_MANAGEMENT}`,
    `/${AppPageRoutingEnum.HR_MANAGEMENT}`,
    `/${AppPageRoutingEnum.CATEGORY_MANAGEMENT}`,
    `/${AppPageRoutingEnum.WMS}`
  ];

  constructor(private routerService: Router, public translate: TranslateService, private pubSubService: PubSubService, private menuConfigService: MenuConfigService,
    private authService: AuthService, public toast: ToastrService, public userPermService: UserPermissionService) {
    this.activeUrl = window.location.pathname;
    this.subsFromAvatarChange = this.pubSubService.onChangeAvatarSubjectEvent.subscribe(data => {
      this.checkImg();
    });

    this.subsFromCollapseMenu = this.pubSubService.onCollapsedSidebarSubjectEvent.subscribe(data => {
      this.isCollapsedSidebar = this.checkIsCollapsed();
    });

    this.isAdmin = this.authService.isAdmin();

    CacheAll.setCookie(CacheConst.SIDEBAR_COLLAPSED, "non-collapsed", TTL.TOKEN);
  }

  ngOnInit() {
    this.initSidebarData();
    this.checkImg();
    this.getUsername();
    this.initSearchMenu("searchMenuInput");
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.subsFromAvatarChange.unsubscribe();
    this.subsFromCollapseMenu.unsubscribe();
  }

  initSidebarData() {
    let mockData = [
      {
        "id": "07554043-00b5-4967-9439-6c51fe54aeee",
        "code": "SYSTEMADMIN",
        "name": "Quản trị hệ thống",
        "isFolder": true,
        "isExpand": false,
        "isAdmin": false,
        "index": "2",
        "icon": "mdi-cogs",
        "childrend": [
          {
            "id": "f0dbc79d-f316-41af-8097-fb19e449878a",
            "code": "UserAdmin",
            "name": "Quản trị người dùng",
            "nameEn": "User Administration",
            "index": "0",
            "sidebarMenuCode": "SYSTEMADMIN",
            "iconUrl": "mdi-account-cog",
            "funcModuleCode": "FMENU",
            "layerUrl": "/system-management/user"
          },
          // {
          //   "id": "c5bba1d9-c888-41f8-a5b1-3e107cd3d40e",
          //   "code": "Permission",
          //   "name": "Phân quyền",
          //   "nameEn": "Permission",
          //   "index": "1",
          //   "sidebarMenuCode": "SYSTEMADMIN",
          //   "iconUrl": "mdi-account-group-outline",
          //   "funcModuleCode": "FMENU",
          //   "layerUrl": "/system-management/permission"
          // },
          // {
          //   "id": "3afb1500-1f0f-4115-bdbe-a91fa56de0b3",
          //   "code": "MailConfig",
          //   "name": "Cấu hình hệ thống mail",
          //   "nameEn": "Mail Config",
          //   "index": "2",
          //   "sidebarMenuCode": "SYSTEMADMIN",
          //   "iconUrl": "mdi-email",
          //   "funcModuleCode": "FMENU",
          //   "layerUrl": "/system-management/mail"
          // },
          // {
          //   "id": "7c6ccc65-caa8-4a4e-9f26-a2d08889d662",
          //   "code": "MenuSysMngStaffConfig",
          //   "name": "Cấu hình nhân sự",
          //   "nameEn": "Menu SysMng Staff Config",
          //   "index": "2",
          //   "sidebarMenuCode": "SYSTEMADMIN",
          //   "iconUrl": "mdi-account-cog",
          //   "funcModuleCode": "FMENU",
          //   "layerUrl": "/system-management/staff-config"
          // },
          // {
          //   "id": "5992a641-891e-4f61-9b35-f10c3f27f2d0",
          //   "code": "MenuConfig",
          //   "name": "Cấu hình nhóm menu",
          //   "nameEn": "Menu Config",
          //   "index": "3",
          //   "sidebarMenuCode": "SYSTEMADMIN",
          //   "iconUrl": "mdi-menu-open",
          //   "funcModuleCode": "FMENU",
          //   "layerUrl": "/system-management/menu-config"
          // },
          {
            "id": "967009e7-b48e-4c16-a310-01359da5e7e1",
            "code": "Log",
            "name": "Nhật ký",
            "nameEn": "Log",
            "index": "4",
            "sidebarMenuCode": "SYSTEMADMIN",
            "iconUrl": "mdi-history",
            "funcModuleCode": "FMENU",
            "layerUrl": "/system-management/log/system"
          }
        ],
        "createdAt": "2022-12-28 07:46:44",
        "updatedAt": "2023-02-16 07:20:58",
        "funcModuleCode": "SIDEBAR_MENU"
      },
      {
        "id": "15ee5596-fcbc-44dc-9ef6-d4b07ec37675",
        "code": "HR_MANAGEMENT",
        "name": "Quản lý nhân sự",
        "isFolder": true,
        "isExpand": false,
        "isAdmin": false,
        "index": "4",
        "icon": "mdi-account-group-outline",
        "childrend": [
          {
            "id": "6ec0f270-8ab1-4ab3-8146-07fa3a68dc3d",
            "code": "TimeOffConfirmation",
            "name": "Quản lý đơn",
            "nameEn": "Time Off Confirmation",
            "index": "5",
            "sidebarMenuCode": "HR_MANAGEMENT",
            "iconUrl": "mdi-account-check",
            "funcModuleCode": "FMENU",
            "layerUrl": "/hrm/confirmation"
          },
          {
            "id": "6ec0f270-8ab1-4ab3-8146-07fa3a68dc3e",
            "code": "Report",
            "name": "Chấm công",
            "nameEn": "Report",
            "index": "6",
            "sidebarMenuCode": "HR_MANAGEMENT",
            "iconUrl": "mdi-check",
            "funcModuleCode": "FMENU",
            "layerUrl": "/hrm/report"
          }
          // {
          //   "id": "6ec0f270-8ab1-4ab3-8146-07fa3a68dc3e",
          //   "code": "Timekeeping",
          //   "name": "Chấm công",
          //   "nameEn": "Timekeeping",
          //   "index": "6",
          //   "sidebarMenuCode": "HR_MANAGEMENT",
          //   "iconUrl": "mdi-check",
          //   "funcModuleCode": "FMENU",
          //   "layerUrl": "/hrm/timekeeping"
          // }
        ],
        "createdAt": "2022-12-28 07:46:44",
        "updatedAt": "2023-02-16 07:21:07",
        "funcModuleCode": "SIDEBAR_MENU"
      },
      {
        "id": "15ee5596-fcbc-44dc-9ef6-d4b07ec37676",
        "code": "WMS",
        "name": "Quản lý kho",
        "isFolder": true,
        "isExpand": false,
        "isAdmin": false,
        "index": "4",
        "icon": "mdi-warehouse",
        "childrend": [
          {
            "id": "6ec0f270-8ab1-4ab3-8146-07fa3a68dc4a",
            "code": "Warehouse",
            "name": "Kho",
            "nameEn": "Warehouse",
            "index": "1",
            "sidebarMenuCode": "WMS",
            "iconUrl": "mdi-warehouse",
            "funcModuleCode": "FMENU",
            "layerUrl": "/wms/warehouse"
          },
          // {
          //   "id": "6ec0f270-8ab1-4ab3-8146-07fa3a68dc4b",
          //   "code": "PurchaseOrders",
          //   "name": "Purchase Orders",
          //   "nameEn": "Purchase Orders",
          //   "index": "2",
          //   "sidebarMenuCode": "WMS",
          //   "iconUrl": "mdi-inbox-arrow-down",
          //   "funcModuleCode": "FMENU",
          //   "layerUrl": "/wms/purchase-orders"
          // },
          // {
          //   "id": "6ec0f270-8ab1-4ab3-8146-07fa3a68dc4p",
          //   "code": "PurchaseOrderDetails",
          //   "name": "Purchase Order Details",
          //   "nameEn": "Purchase Order Details",
          //   "index": "3",
          //   "sidebarMenuCode": "WMS",
          //   "iconUrl": "mdi-inbox-arrow-down",
          //   "funcModuleCode": "FMENU",
          //   "layerUrl": "/wms/purchase-order-details"
          // },
          // {
          //   "id": "6ec0f270-8ab1-4ab3-8146-07fa3a68dc4b",
          //   "code": "Inbound",
          //   "name": "Nhập kho",
          //   "nameEn": "Inbound",
          //   "index": "2",
          //   "sidebarMenuCode": "WMS",
          //   "iconUrl": "mdi-inbox-arrow-down",
          //   "funcModuleCode": "FMENU",
          //   "layerUrl": "/wms/inbound"
          // },
          // {
          //   "id": "6ec0f270-8ab1-4ab3-8146-07fa3a68dc4p",
          //   "code": "InboundDetails",
          //   "name": "Nhập kho chi tiết",
          //   "nameEn": "Inbound Details",
          //   "index": "3",
          //   "sidebarMenuCode": "WMS",
          //   "iconUrl": "mdi-inbox-arrow-down",
          //   "funcModuleCode": "FMENU",
          //   "layerUrl": "/wms/inbound-details"
          // },
          // {
          //   "id": "6ec0f270-8ab1-4ab3-8146-07fa3a68dd4b",
          //   "code": "Adjustments",
          //   "name": "Phiếu điều chỉnh",
          //   "nameEn": "Adjustments",
          //   "index": "2",
          //   "sidebarMenuCode": "WMS",
          //   "iconUrl": "mdi-inbox-arrow-down",
          //   "funcModuleCode": "FMENU",
          //   "layerUrl": "/wms/adjustments"
          // },
          // {
          //   "id": "6ec0f270-8ab1-4ab3-8146-07fa3a68fc4p",
          //   "code": "AdjustmentDetails",
          //   "name": "Phiếu điều chỉnh chi tiết",
          //   "nameEn": "Adjustment Details",
          //   "index": "3",
          //   "sidebarMenuCode": "WMS",
          //   "iconUrl": "mdi-inbox-arrow-down",
          //   "funcModuleCode": "FMENU",
          //   "layerUrl": "/wms/adjustment-details"
          // },
          // {
          //   "id": "6ec0f270-8ab1-4ab3-8146-07fa3a68dc4c",
          //   "code": "Outbound",
          //   "name": "Xuất kho",
          //   "nameEn": "Outbound",
          //   "index": "4",
          //   "sidebarMenuCode": "WMS",
          //   "iconUrl": "mdi-inbox-arrow-up",
          //   "funcModuleCode": "FMENU",
          //   "layerUrl": "/wms/outbound"
          // },
          // {
          //   "id": "6ec0f270-8ab1-4ab3-8146-07fa3a68dc11",
          //   "code": "OutboundDetails",
          //   "name": "Xuất kho chi tiết",
          //   "nameEn": "Outbound Details",
          //   "index": "5",
          //   "sidebarMenuCode": "WMS",
          //   "iconUrl": "mdi-inbox-multiple",
          //   "funcModuleCode": "FMENU",
          //   "layerUrl": "/wms/outbound-details"
          // },
          // {
          //   "id": "6ec0f270-8ab1-4ab3-8146-07fa3a68dc4d",
          //   "code": "Inventory",
          //   "name": "Tồn kho",
          //   "nameEn": "Inventory",
          //   "index": "6",
          //   "sidebarMenuCode": "WMS",
          //   "iconUrl": "mdi-inbox-multiple",
          //   "funcModuleCode": "FMENU",
          //   "layerUrl": "/wms/inventory"
          // }
        ],
        "createdAt": "2022-12-28 07:46:44",
        "updatedAt": "2023-02-16 07:21:07",
        "funcModuleCode": "SIDEBAR_MENU"
      },{
        "id": "15ee5596-fcbc-44dc-9ef6-d4b07ec37677",
        "code": "CATEGORY",
        "name": "Quản lý danh mục",
        "isFolder": true,
        "isExpand": false,
        "isAdmin": false,
        "index": "8",
        "icon": "mdi-clipboard-edit",
        "childrend": [
          {
            "id": "6ec0f270-8ab1-4ab3-8146-07fa3a68dc5a",
            "code": "Category",
            "name": "Danh mục",
            "nameEn": "Category",
            "index": "1",
            "sidebarMenuCode": "CATEGORY",
            "iconUrl": "mdi-clipboard-list",
            "funcModuleCode": "FMENU",
            "layerUrl": "/category-management/categories"
          }
        ],
        "createdAt": "2022-12-28 07:46:44",
        "updatedAt": "2023-02-16 07:21:07",
        "funcModuleCode": "SIDEBAR_MENU"
      }
    ] as SidebarMenuGroupModel[];
    this.menuConfigService.sidebarMenu = mockData;
    this.sidebarMenuData = mockData;
    this.showingSidebarMenuData = mockData;
    this.subscribeChangeRouting();

    // this.menuConfigService.getListSystemSidebarMenus("asc", "index", "").subscribe(rs => {
    //   let result = rs as ApiReturnModel;
    //   if (result.code == 0) {
    //     this.menuConfigService.sidebarMenu = result.data;
    //     this.sidebarMenuData = result.data;
    //     this.showingSidebarMenuData = result.data;
    //     this.subscribeChangeRouting();
    //   } else {
    //     if (!this.authService.checkInvalidResponse(result)) this.toast.error(result.message);
    //   }
    // }, error => {
    //   this.toast.error(error.message);
    // })
  }

  subscribeChangeRouting(){
    if (this.activeUrl) this.checkValidRouting();
    this.routerService.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.activeUrl = location.pathname;
        this.checkValidRouting();
      }
    })
  }

  canCheckThisPage() {
    let check = false;
    for (let str of this.checkValidPage) {
      if (this.activeUrl.includes(str)) check = true;
    }
    return check;
  }

  checkValidRouting() {
    if (this.canCheckThisPage()) {
      let isValid = false;
      for(let data of this.showingSidebarMenuData) {
        for (let child of data.childrend) if (child.layerUrl && (child.layerUrl.includes(this.activeUrl) || this.activeUrl.includes(child.layerUrl))) {
          isValid = true;
          break;
        }
      }
      if (!isValid) {
        this.routerService.navigate([`${AppPageRoutingEnum.ERROR}/401`]);
      }
    }
  }

  checkActiveSidebarMenu(data: SidebarMenuGroupModel) :boolean{
    let isActive = false;
    let childrend = data.childrend
    for (let child of childrend) {
      if (child.layerUrl && this.activeUrl.includes(child.layerUrl)) {
        isActive = true;
        break;
      }
    }
    return isActive;
  }

  //#region User Action & Detail
  checkImg() {
    let imgUrl = CacheAll.getCookie(CacheConst.USER_IMAGE);
    this.avatarUrl = imgUrl;
    return (this.avatarUrl.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null);
  }

  getUsername() {
    let json = CacheAll.getCookie(CacheConst.USER_DETAIL);
    if (json) {
      let userDetail = JSON.parse(Base64.decode(CacheAll.getCookie(CacheConst.USER_DETAIL)));
      this.userLastname = userDetail.lastName;
      this.userFirstname = userDetail.firstName;
    } else {
      this.userLastname = "H";
      this.userFirstname = "T";
    }
  }

  submitLogout() {
    //clear old cookie
    this.authService.logout().subscribe(rs => {
      let result = rs as ApiReturnModel;
      if (result.code != 0) {
        this.toast.error(result.message);
      }
    }, error => {
      this.toast.error(error.message);
    })
    CacheAll.deleteCookie(CacheConst.USER_TOKEN);
    CacheAll.deleteCookie(CacheConst.USER_ID);
    CacheAll.deleteCookie(CacheConst.USER_IMAGE);
    CacheAll.deleteCookie(CacheConst.USER_DETAIL);
    CacheAll.deleteCookie(CacheConst.USER_SESSION);
    CacheAll.deleteCookie(CacheConst.CUSTOMER_CODE);
    this.routerService.navigate(['auth'], { queryParams: {} });
  }

  hiddenSidebar() {
    this.pubSubService.onSidebarClick();
  }

  checkIsCollapsed() {
    let bool = CacheAll.getCookie(CacheConst.SIDEBAR_COLLAPSED);
    if (bool === "collapsed") return true;
    else if (bool === "non-collapsed") return false;
    else return false
  }

  toggleExpand(data: SidebarMenuGroupModel){
    data.isExpand = !data.isExpand;
    if (data.isExpand) this.showingSidebarMenuData.map((menu: SidebarMenuGroupModel) => {
      if (menu.id != data.id) menu.isExpand = false;
    })
  }
  //#endregion

  //#region Search Menu
  searchMenuLocal(str: string) {
    str = str.toLowerCase();
    let returnData = [] as SidebarMenuGroupModel[];
    for (let data of this.sidebarMenuData){
      if (data.name.toLowerCase().includes(str)) returnData.push(data);
      else if (data.childrend.length > 0) {
        for (let child of data.childrend) {
          if (child.name.toLowerCase().includes(str)) {
            data.isExpand = true;
            returnData.push(data);
            break;
          };
        }
      }
    }
    return returnData;
  }

  initSearchMenu(divId) {
    let searchDom = document.getElementById(divId) as HTMLInputElement;
    searchDom.addEventListener("keydown", this.delay(function (e) {
      if (searchDom.value) {
        this.showingSidebarMenuData = this.searchMenuLocal(searchDom.value);
        return;
      } else {
        this.showingSidebarMenuData = this.sidebarMenuData;
      }
    }, 300).bind(this));
  }

  delay(callback, ms) {
    var timer;
    return function () {
      var context = this, args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        callback.apply(context, args);
      }, ms || 0);
    };
  }

  //#endregion
}
