import { Component, OnInit, ChangeDetectorRef} from '@angular/core';
import { Router, NavigationEnd, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@core/services/root/auth/auth.service';
import { PubSubService } from '@core/services/root/pub-sub/pub-sub.service';
import { CacheAll } from '@core/lib/cache';
import { TTL, CacheConst } from '@core/constant/shared/system.const';
import { AppPageRoutingEnum } from '@shared/enums/routing.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  title = 'demo1';
  showSidebar: boolean = true;
  showNavbar: boolean = false;
  showFooter: boolean = false;
  isLoading: boolean;
  public iconOnlyToggled = false; //hiddenSidebar =>  add class="sidebar-icon-only" to index.html body, iconOnlyToggled -> true
  public sidebarToggled = true;
  isLoginUser: boolean = false;

  constructor(private router: Router, public translate: TranslateService, private ref: ChangeDetectorRef, 
    private pubSubService: PubSubService, private authService: AuthService) {
    translate.addLangs(['vi']);
    translate.setDefaultLang('vi');

    // Removing Sidebar, Navbar, Footer for Documentation, Error and Auth pages
    this.isLoginUser = this.authService.isLoginUser();
    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd){
        if(
          (!event['url'].includes(`/${AppPageRoutingEnum.USER_PROFILE}`)) &&
          (!event['url'].includes(`/${AppPageRoutingEnum.SYSTEM_MANAGEMENT}`)) &&
          (!event['url'].includes(`/${AppPageRoutingEnum.HR_MANAGEMENT}`)) &&
          (!event['url'].includes(`/${AppPageRoutingEnum.CATEGORY_MANAGEMENT}`))&&
          (!event['url'].includes(`/${AppPageRoutingEnum.WMS}`))
        )
        {
          this.showSidebar = false;
          // this.showNavbar = false;
          // this.showFooter = false;
          document.querySelector('.main-panel').classList.add('w-100');
          document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
          document.querySelector('.main-content').classList.remove('auth', 'auth-img-bg', );
          document.querySelector('.main-content').classList.remove('auth', 'lock-full-bg');
          if((event['url'] == '/error/404') || (event['url'] == '/error/401')) {
            document.querySelector('.main-content').classList.add('p-0');
          }
        } else {
          this.showSidebar = true;
          // this.showNavbar = true;
          // this.showFooter = true;
          document.querySelector('.main-panel').classList.remove('w-100');
          document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
          document.querySelector('.main-content').classList.remove('auth', 'auth-img-bg');
          document.querySelector('.main-content').classList.remove('p-0');
        }
        this.ref.detectChanges();
      }
    })

    // Spinner for lazyload modules
    router.events.forEach((event) => {
      if (event instanceof RouteConfigLoadStart) {
          this.isLoading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
          this.isLoading = false;
      }
    });

    this.pubSubService.onSidebarClickSubjectEvent.subscribe(data=>{
      this.toggleSidebar();
    });
  }

  ngOnInit() {
    // Scroll to top after route change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
  }

  toggleSidebar(){
    let body = document.querySelector('body');
    if((!body.classList.contains('sidebar-toggle-display')) && (!body.classList.contains('sidebar-absolute'))) {
      this.iconOnlyToggled = !this.iconOnlyToggled;
      if(this.iconOnlyToggled) {
        body.classList.add('sidebar-icon-only');//đóng
        CacheAll.setCookie(CacheConst.SIDEBAR_COLLAPSED, "collapsed", TTL.TOKEN);
        this.pubSubService.onCollapsedSidebar();
      } else {
        body.classList.remove('sidebar-icon-only');//mở
        CacheAll.setCookie(CacheConst.SIDEBAR_COLLAPSED, "non-collapsed", TTL.TOKEN);
        this.pubSubService.onCollapsedSidebar();
      }
    } else {
      this.sidebarToggled = !this.sidebarToggled;
      if(this.sidebarToggled) {
        body.classList.add('sidebar-hidden');//đóng
      } else {
        body.classList.remove('sidebar-hidden');//mở
      }
    }
  }
  
   // toggle sidebar in small devices
   toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
}
