import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sort-arrow',
  templateUrl: './sort-arrow.component.html',
  styleUrls: ['./sort-arrow.component.scss']
})
export class SortArrowComponent implements OnInit {
  @Input() sortBy: string;
  @Input() sortOrder: string;
  @Input() columnName: string;

  constructor() { }

  ngOnInit(): void {
  }

}
