import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Basic } from '@core/lib/basic';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MdiIconList } from '@core/constant/shared/mdi.const';

@Component({
  selector: 'icon-selectbox',
  templateUrl: './icon-selectbox.component.html',
  styleUrls: ['./icon-selectbox.component.scss']
})
export class IconSelectboxComponent implements OnInit {
  @Input() selectedIcon: string;
  @Input() disabled: boolean;
  @Output() onSelect = new EventEmitter<any>();

  searchKey: string = "";
  scrollbarConfig: PerfectScrollbarConfigInterface =  {};
  showingData: string[] = [];
  searchId: string = "";
  title: string = "global_icon_selection-title";
  showingTitle: string = "";
  data: string[] = MdiIconList;
  constructor() {
    this.searchId = Basic.uuidGenerator();
  }

  ngOnChanges() {
    if (!this.selectedIcon) this.showingTitle = this.title;
    else this.showingTitle = this.selectedIcon
  }

  ngOnInit(): void {
    if (typeof this.disabled != "boolean") this.disabled = false;
    if (!this.selectedIcon) this.showingTitle = this.title;
    else this.showingTitle = this.selectedIcon
  }

  onClickDropdown(){
    this.searchKey = "";
    let inputElm = document.getElementById(this.searchId) as HTMLInputElement;
    inputElm.value = this.searchKey;
    this.showingData = this.data;
  }

  onChange($event){
    let icon = $event.target.value;
    this.onSelect.emit(icon);
  }

  selectedRow(icon){
    this.showingTitle = icon;
    this.onSelect.emit(icon)
  }

  onKeyUpSearch($event){
    this.searchKey = decodeURIComponent($event.search_string);
    if (this.searchKey !== "") this.searchData(this.searchKey);
    else this.showingData = this.data;
  }

  searchData(key: string){
    this.showingData = [];
    for (let icon of this.data){
      if (icon.toLowerCase().includes(key.toLowerCase())) this.showingData.push(icon);
    }
  }

  refresh(){
    this.showingTitle = this.title;
  }
}
