export const TTL = {
    TOKEN: 1000*60*60*8,
    DEVICEID: 1000*60*60*24*30,
    DICTIONARY: 1000*60*60*24,
    SESSION: 1000*60*60*24
}

export const Constant = {
    UNKNOWN_IMG_URL: "assets/images/icons/unknown_user.jpg",
    DOCUMENT_URL: "assets/file/documentations/public-maps/PUBLICMAPS.pdf",
    DEFAULT_ROLES: ['ADMIN', 'OPERATOR', 'LGSP'],
    APPLIES_NULL_EDIT_USER_FIELDS: ["avatarUrl", "phone", "address", "birthday", "gender", "secondPhone"],
    APPLIES_NULL_EDIT_USER_FIELDS_FOR_ADMIN: ["avatarUrl", "phone", "address", "birthday", "gender", "secondPhone", "department", "position"],
}

export const CacheConst = { 
    DEVICE_ID: "device:id",
    USER_TOKEN: "token",
    ANONYMOUS_TOKEN: "anonymous",
    USER_DETAIL: "user:detail",
    USER_ID: "user_id",
    USER_IMAGE: "user:image_url",
    USER_SESSION: "user:sessions",
    RESETPASS_MAIL: "repass:mail",
    SIDEBAR_COLLAPSED: "sidebar:collapsed",
    DICTIONARY_FIELDS: "dictionary:fields",
    CUSTOMER_CODE: "customer_code",
    NOTIFICATION_TOTAL: "totalNotification",
}