import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppPageRoutingEnum } from '@shared/enums/routing.enum';

const routes: Routes = [
  { path: '', redirectTo: AppPageRoutingEnum.AUTH, pathMatch: 'full' },
  { path: AppPageRoutingEnum.AUTH, loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule) },  
  { path: AppPageRoutingEnum.USER_PROFILE, loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule) },
  { path: AppPageRoutingEnum.ERROR, loadChildren: () => import('./pages/error-pages/error-pages.module').then(m => m.ErrorPagesModule) },
  { path: AppPageRoutingEnum.SYSTEM_MANAGEMENT, loadChildren: () => import('./pages/system-management/system-management.module').then(m => m.SystemManagementModule) },
  { path: AppPageRoutingEnum.HR_MANAGEMENT, loadChildren: () => import('./pages/human-resource-management/human-resource-management.module').then(m => m.HumanResourceManagementModule) },
  { path: AppPageRoutingEnum.CATEGORY_MANAGEMENT, loadChildren: () => import('./pages/category-management/category-management.module').then(m => m.CategoryManagementModule) },
  { path: AppPageRoutingEnum.WMS, loadChildren: () => import('./pages/wms/wms.module').then(m => m.WmsModule) },
  { path: '**', redirectTo: AppPageRoutingEnum.ERROR},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
