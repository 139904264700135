<ng-template #uploadFileModal let-modal>
    <div class="modal-body text-center">
        <b class="mb-2">{{title | translate}}</b>
        <p class="sub-title mt-3">{{subtitle | translate}}</p>
        <div class="custom-file">
            <input type="file" class="form-control mb-2 custom-file-input" id="fileInput" (change)="onFileSelected($event)" accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword">
            <label class="custom-file-label" for="fileInput">{{"global_upload_file-title" | translate}}</label>
        </div>
        <div class="mx-auto">
            <button type="button" class="btn btn-success mr-2 p-2" [disabled]="isDisabled" (click)="clickLink()">{{'global_submit' | translate}}</button>
            <button type="button" class="btn btn-secondary p-2" (click)="close()">{{'global_cancel' | translate}}</button>
        </div>
    </div>
</ng-template>