export const SystemConst = {
    //LAYER GROUP
    LAYER_GROUP_GET_LIST_PERM: "System/LayerGroup?sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}&action={action}",
    LAYER_GROUP_GET_LIST: "System/Config/LayerGroup?sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    LAYER_GROUP_GET_LIST_PAGING: "System/Config/LayerGroup/listPaging?pageIndex={page}&pageSize={pageSize}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    LAYER_GROUP_CRUD: "System/Config/LayerGroup/{id}",
    //DATA LAYER CLASS
    DATA_LAYER_GET_LIST: "System/DataLayerClass?sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    DATA_LAYER_GET_LIST_PAGING: "System/DataLayerClass/listPaging?pageIndex={page}&pageSize={pageSize}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    DATA_LAYER_CRUD: "System/DataLayerClass/{id}",
    //SIDEBAR GROUP MENU = SidebarMenu
    SIDEBAR_GROUP_MENU_GET_LIST: "System/Config/SidebarMenu?sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    SIDEBAR_GROUP_MENU_LIST_PAGING: "System/Config/SidebarMenu/listPaging?pageIndex={page}&pageSize={pageSize}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    SIDEBAR_GROUP_MENU_CRUD: "System/Config/SidebarMenu/{id}",
    //MENU CLASS
    MENU_GET_LIST: "System/MenuClass?sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    MENU_GET_LIST_PAGING: "System/MenuClass/listPaging?pageIndex={page}&pageSize={pageSize}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    MENU_CRUD: "System/MenuClass/{id}",
    //SYSTEM SIDEBAR
    SYSTEM_SIDEBAR_GET_LIST: "System/SidebarMenu?sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}"
}