import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, APP_INITIALIZER  } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { ClipboardModule } from 'ngx-clipboard';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HighchartsChartModule } from 'highcharts-angular';
import { ToastrModule } from 'ngx-toastr';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { AppComponent } from './app.component';
import { SidebarComponent } from '@shared/components/sidebar/sidebar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContentAnimateDirective } from './shared/directives/content-animate.directive';
import { TranslateModule, TranslateService, TranslateLoader } from '@ngx-translate/core';
import { ApplicationInitializerFactory, HttpLoaderFactory } from '@core/config/translate.config';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ReusedModule } from '@reused/reused.module';
import { SharedModule } from '@shared/shared.module';

import { AuthService } from '@core/services/root/auth/auth.service';
import { ErrorInterceptorProvider } from './core/interceptor/handle-error.intercept';
import { HeaderInterceptorProvider } from './core/interceptor/header-config.intercept';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    SpinnerComponent,
    ContentAnimateDirective,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    HighchartsChartModule,
    NgxChartsModule,
    ToastrModule.forRoot({
      positionClass :'toast-bottom-right',
      preventDuplicates: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ReusedModule,
    SharedModule
  ],
  providers: [
    AuthService,
    HeaderInterceptorProvider,
    ErrorInterceptorProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationInitializerFactory,
      deps: [ TranslateService, Injector ],
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
