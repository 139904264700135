import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CacheAll } from '@core/lib/cache';
import { UserService } from '../../pages/user/shared/services/user.service';
import { ApiReturnModel } from '@shared/models/global.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CacheConst } from '@core/constant/shared/system.const';
import { Basic } from '@core/lib/basic';
import { FileSizeType } from '@shared/enums/file-size-type.enum';
import { AuthService } from '@core/services/root/auth/auth.service';

@Component({
  selector: 'change-avatar-modal',
  templateUrl: './change-avatar-modal.component.html',
  styleUrls: ['./change-avatar-modal.component.scss']
})
export class ChangeAvatarModalComponent implements OnInit {

  @Output() onClick = new EventEmitter<object>();
  @Input() centerPage:boolean;
  @Input() size: string;
  @Input() resizeImage: string;
  @Input() title: string;
  @Input() subtitle: string;
  
  @ViewChild('changeAvtModal') modal: any;
  
  closeResult: string;
  modalReference: NgbModalRef;
  confirmData: any;
  isDisabled: boolean = true;
  fileUpload: any;
  uploadFileType: string = "";
  uploadFileName: string = "";
  token: string
  imgUrl: string | ArrayBuffer;
  isResize: boolean = false;
  _height: number;
  _width: number;

  constructor(public authService: AuthService, private modalService: NgbModal, public userService: UserService, public toast: ToastrService, public translate: TranslateService) { 
   
  }

  ngOnInit(): void {
    this.token = CacheAll.getCookie(CacheConst.USER_TOKEN);
    if (!this.centerPage) this.centerPage = false;
    if (!this.size) this.size = 'md'; //size: sm, md, lg
    if (!this.title) this.title = "user-change_avatar-title";
    if (!this.subtitle) this.subtitle = "user-change_avatar-content";
    if (!this.resizeImage) this.isResize = false;
    else {
      this.isResize = true;
      let data = this.resizeImage.split(";")
      this._height = parseFloat(data[0]) ? parseFloat(data[0]) : 512;
      this._width = parseFloat(data[1]) ? parseFloat(data[1]) : 512;
    } 
  }

  onFileSelected($event){
    this.isDisabled = true;
    let file = $event.target.files[0] as File;
    //check if upload file
    if (file) this.fileUpload = null;
    else if (!file && this.fileUpload) file = this.fileUpload;
    //check file exist
    if (file) {
      this.uploadFileType = file.type;
      let size = file.size;
      //rename input
      this.uploadFileName = Basic.removeAllSpaces(file.name);
      let nextSibling = $event.target.nextElementSibling;
          nextSibling.innerText = this.uploadFileName;
      let sizeInMB = Basic.formatFileSize(size, FileSizeType.mb);
      if (this.uploadFileType === "image/gif" || this.uploadFileType === "image/png" || this.uploadFileType === "image/jpeg") {
        if (sizeInMB <= 15) {
          this.isDisabled = false;
          //convert new file
          let blob = file.slice(0, size, this.uploadFileType); 
          this.fileUpload = new File([blob], this.uploadFileName, {type: this.uploadFileType});
          //review avatar
          const reader = new FileReader();
          reader.onload = e => this.imgUrl = reader.result;
          reader.readAsDataURL(this.fileUpload);
        }
        else this.toast.error(this.translate.instant("user-upload_avatar-invalid_size"));
      }
      else this.toast.error(this.translate.instant("user-upload_avatar-invalid_format"));
    }
  }

  clickLink(){
    //check resize
    if (this.isResize) {
      var img = new Image;
      var c = document.createElement("canvas");
      var ctx = c.getContext("2d");
      img.onload = function() {
        c.width = this._width;
        c.height = this._height;
        ctx.drawImage(img, 0, 0, this._width, this._height);
        c.toBlob(function(blob) {
          this.fileUpload = new File([blob], this.uploadFileName, {type: this.uploadFileType});
          this.uploadImage(this.fileUpload);
        }.bind(this), this.uploadFileType, 1);
      }.bind(this);
      img.crossOrigin = "";
      img.src = this.imgUrl.toString();
    } else this.uploadImage(this.fileUpload);
  }

  uploadImage(file){
    this.userService.uploadAvatar(file).subscribe(rs => {
      let result = rs as ApiReturnModel;
      if (result.code == 0) {
        let avtUrl = result.data[0].downloadFileURI;
        this.onClick.emit(avtUrl);
      } else {
        if (!this.authService.checkInvalidResponse(result)) this.toast.error(result.message);
      }
    }, error => {
      this.toast.error(error.message);
      this.onClick.emit(null);
    })
  }

  open() {
    this.modalReference = this.modalService.open(this.modal, {
      ariaLabelledBy: 'modal-basic-title', 
      centered: this.centerPage,
      size: this.size
    });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.imgUrl = "";
    this.isDisabled = true;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  close(){
    this.modalReference.close();
  }

}
