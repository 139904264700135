export const CommonCatsConst = {
    //PROVINCE
    PROVINCE_LIST: "CommonCats/Province?sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    PROVINCE_LIST_PAGING: "CommonCats/Province/listPaging?pageIndex={page}&pageSize={pageSize}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    PROVINCE_CRUD: "CommonCats/Province/{id}",
    //DISTRICT
    DISTRICT_LIST: "CommonCats/District?provinceCode={provinceCode}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    DISTRICT_LIST_PAGING: "CommonCats/District/listPaging?provinceCode={provinceCode}&pageIndex={page}&pageSize={pageSize}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    DISTRICT_CRUD: "CommonCats/District/{id}",
    //WARD
    WARD_LIST: "CommonCats/Ward?districtCode={districtCode}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    WARD_LIST_PAGING: "CommonCats/Ward/listPaging?districtCode={districtCode}&pageIndex={page}&pageSize={pageSize}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    WARD_CRUD: "CommonCats/Ward/{id}",
    //DEPARTMENT
    DEPARTMENT_LIST: "CommonCats/Department?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    DEPARTMENT_DETAIL:  "CommonCats/Department/{id}",
    DEPARTMENT_GET_BY_PARENTID: "CommonCats/Department/of/{id}",
    DEPARTMENT_CREATE: "CommonCats/Department",
    DEPARTMENT_UPDATE: "CommonCats/Department/{id}",
    DEPARTMENT_DELETE: "CommonCats/Department/{id}",
    DEPARTMENT_GET_HIDE: "CommonCats/Department/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    DEPARTMENT_GET_SHOW: "CommonCats/Department/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    //POSITION
    POSITION_LIST: "CommonCats/Position?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    POSITION_DETAIL: "CommonCats/Position/{id}",
    POSITION_GET_BY_PARENTID: "CommonCats/Position/of/{id}",
    POSITION_CREATE: "CommonCats/Position",
    POSITION_UPDATE: "CommonCats/Position/{id}",
    POSITION_DELETE: "CommonCats/Position/{id}",
    POSITION_GET_HIDE: "CommonCats/Position/hide/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    POSITION_GET_SHOW: "CommonCats/Position/show/{userId}?sortBy={sortBy}&sortOrder={sortOrder}&searchKey={searchKey}",
    //OBJ TYPE
    OBJ_TYPE_GET_LIST: "CommonCats/ObjType",
    OBJ_TYPE_GET_LIST_PAGING: "CommonCats/ObjType/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    OBJ_TYPE_GET_LIST_BY_OBJ_CLASSS: "CommonCats/ObjType/byObjClass/{objClass}?searchKey={searchKey}",
    //CATEGORY 
    CATEGORY_GET_LIST:"CommonCats/{cateCode}?{catesubCode}={subCode}&searchKey={searchKey}&sort=asc&sortBy=name",
    CATEGORY_CREATE: "CommonCats/{cateCode}",
    CATEGORY_RUD: "CommonCats/{cateCode}/{id}",
    //SPATIAL_QUERY 
    SPATIAL_QUERY_ADMINISTRATIVE: 'CommonCats/{layer_code}?srid={srid}&point={point}',
}