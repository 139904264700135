<div class="page-header">
    <h3 *ngIf="isMainPage" class="page-title text-uppercase">
        <span class="page-title-icon bg-primary text-white mr-2"><i class="mdi {{pageIcon}} mdi-18px"></i></span> 
        {{pageTitle | translate}}
    </h3>
    <h3 *ngIf="!isMainPage" class="page-title text-uppercase">
        <button class="btn btn-exit" [tooltip]="'global_back' | translate" (click)="back()"><i class="mdi mdi-keyboard-backspace"></i></button>
   </h3>
    <nav class="breadcrumb-nav" aria-label="breadcrumb">
        <ol class="breadcrumb">
            <ng-container *ngFor="let bc of breadcrumb">
                <li *ngIf="bc.level == 0 && breadcrumb.length > 1" class="breadcrumb-item"><a class="cursor-disabled">{{ bc.name | translate}}</a></li>
                <li *ngIf="(bc.level == 1 && breadcrumb.length == 3)" class="breadcrumb-item"><a [routerLink]="bc.routerLink">{{ bc.name | translate}}</a></li>
                <li *ngIf="(bc.level == 1 && breadcrumb.length == 2) || (bc.level == 2 && breadcrumb.length == 3) || (bc.level == 0 && breadcrumb.length == 1)" class="breadcrumb-item active" aria-current="page">{{ bc.name | translate}}</li>
            </ng-container>
        </ol>
    </nav>
</div>