export const Constant = {
    /*
        Đang để tạm
        Sau này có thể không dùng constant này nữa
    */
    //subtable
    SUBTABLE_GET_LIST_BY_CODE: 'Management/{subtableCode}/{tableCode}/byCode/{bindingCode}?searchKey={searchKey}',
    SUBTABLE_CRUD: 'Management/{subtableCode}/{tableCode}/{id}',
    //Schedule
    SAVE_WORK_SCHEDULE: "PBWorkSchedule",
    LIST_WORK_SCHEDULE: "PBWorkSchedule?searchKey={searchKey}",
    LIST_HOLIDAY_SCHEDULE: "PBHolidaySchedule?searchKey={searchKey}&sortOrder=asc&sortBy=startDate",
    LIST_PAGING_HOLIDAY_SCHEDULE: "PBHolidaySchedule/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    CRUD_HOLIDAY_SCHEDULE: "PBHolidaySchedule/{id}",
    //Fields
    FIELDS_GET_DROPDOWN: '{code}/{parent_id}',

    LAYER_GET_FIELDS_WITH_SEARCH: 'objField?objClassCode={layer_code}&objClassSubCode={layer_subCode}&searchKey={searchKey}&sortOrder=asc&sortBy=seqNum', 
    LAYER_GET_CRITERIA_FIELDS: 'objField/criteria?objClassCode={layer_code}&objClassSubCode={layer_subCode}&searchKey={searchKey}&sortOrder=asc&sortBy=seqNum', 
    LAYER_GET_FIELDS_DETAILS: 'objField/{field_id}', 
    LAYER_UPDATE_FIELDS: 'objField/{field_id}',

    LAYER_LIST_GET_LIST: 'objClass?searchKey={searchKey}&funcModuleCodes={funcCode}&sortOrder={sort}&sortBy={sortBy}',
    LAYER_LIST_GET_LIST_BY_CODE: 'objClass?code={code}&subCode={subCode}',
    LAYER_LIST_GET_DETAIL: 'objClass/{layer_id}',
    LAYER_LIST_UPDATE: 'objClass/{layer_id}',
    //SIDEBAR MENU
    SIDEBAR_MENU_CRUD: 'CommonCats/ObjType/System/SidebarMenu/{id}',
    //GROUP LAYER
    GROUP_LAYER_CRUD: 'CommonCats/ObjType/System/LayerGroup/{id}',
    //STAFF CONFIG && GROUPPERM OF CLUSTER
    CLUSTER_CONFIG_GET_LIST: "ClusterPeGrConfig?sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    CLUSTER_CONFIG_GET_LIST_PAGING: "ClusterPeGrConfig/listPaging?pageIndex={page}&pageSize={pageSize}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}&funcModuleCode={funcModuleCode}",
    CLUSTER_CONFIG_CRUD: "ClusterPeGrConfig/{id}",
    CLUSTER_CONFIG_GET_LIST_PAGING_FUNC_MODULE: "ClusterPeGrConfig/funcModule/listPaging?pageIndex={page}&pageSize={pageSize}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    CLUSTER_GROUPPERM_GET_LIST: "ClusterPermGroup?code={code}",
    CLUSTER_GROUPPERM_GET_LIST_PAGING: "ClusterPermGroup/listPaging?code={code}",
    CLUSTER_GROUPPERM_CRUD: "ClusterPermGroup/byCluster/{code}",
    CLUSTER_GROUPPERM_GET_USERS: "ClusterPermGroup/byCluster/{code}?searchKey={searchKey}",
}
